import React, { useEffect, useState } from "react";
import {
  Pressable,
  View,
  Image,
  TouchableOpacity,
  Alert,
  Text,
  Platform,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import * as ImagePicker from "expo-image-picker";
import * as DocumentPicker from "expo-document-picker";
import { ScrollView } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { createAPost } from "../../actions/auth-actions";
import { createCrossPlatformFormData, fileURLtoBlob } from "../../utils/encDec";
import { PleaseWaitModal } from "../../components/modal";
import ResponseModal from "../../components/general/modals/ResponseModal";
import CameraCustomView from "../../components/general/inputs/CameraCustomView";

export default function CreateGroupPost({
  navigation,
  route,
  groupId,
  checkRefreshListforPosts,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showCameraView, setShowCameraView] = useState(false);
  const [postDescription, setIsPostDescription] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  // const [imageDimensions, setImageDimensions] = useState({ width: 1, height: 1 });

  // useEffect(() => {
  //   if (selectedImage) {
  //     console.log(selectedImage,"selectedImage s");
      
  //     Image.getSize(
  //       selectedImage,
  //       (width, height) => setImageDimensions({ width, height }),
  //       (error) => console.log("Error getting image dimensions:", error)
  //     );
  //   }
  // }, [selectedImage]);
  const [postExternalURL, setPostExternalURL] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileObj, setSelectedFileObj] = useState(null);
  const [selectedImageObj, setSelectedImageObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });

  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const openCamera = async () => {
    setShowCameraView(true);
  };

  const openFilePicker = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: "*/*",
      copyToCacheDirectory: true,
    });
    if (result.type !== "cancel") {
      let path = result.uri.replace("file:///", "file:/");
      console.log(result.uri, "result.uri");
      console.log(result, "result");
      setSelectedFileName(result.name);
      setSelectedFile(result.uri);
      // console.log(selectedFile, result, "selectedFile");
      fileURLtoBlob(path)
        .then((data) => {
          setSelectedFileObj({
            uri: result.uri,
            name: result.name,
            type: result.mimeType,
            blob: data,
          });
        })
        .catch((error) => {
          console.log("fileURLtoBlob-error", error);
        });
    }
  };

  const openGallery = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 0.8,
      allowsEditing: true,
    });

    if (!result.canceled) {
      let imageObject = result?.assets[0];
      let path = imageObject?.uri?.replace("file:///", "file:/");
      setSelectedImage(result.assets[0].uri);
      if (result.assets[0].uri !== null) {
        fileURLtoBlob(path)
          .then((data) => {
            imageObject.blob = data;
            setSelectedImageObj(imageObject);
          })
          .catch((error) => {
            console.log("fileURLtoBlob-error", error);
          });
      }
    }
  };

  const CreatePostForGroup = async () => {
    try {
      setIsLoading(true);
      const dataObj = {};

      dataObj.title = "" || undefined;
      dataObj.external_link_url = postExternalURL || undefined;
      dataObj.description = postDescription || undefined;
      dataObj.cover_image = selectedImageObj || undefined;
      dataObj.attached_file = selectedFileObj || undefined;

      const formData = createCrossPlatformFormData({
        blobDataKeys: ["cover_image", "attached_file"],
        data: dataObj,
      });
      if (Object.keys(dataObj)?.length) {
        const res = await createAPost(groupId, formData);
        if (res.status == 201) {
          setSelectedImage(null);
          setSelectedFile(null);
          setIsPostDescription("");
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: "Post Created Successfully !!!",
          }));
          checkRefreshListforPosts();
        } else {
          setIsLoading(false);
          setIsResponseModal((prev) => ({
            ...prev,
            visible: true,
            message: res?.data?.message || "Post Creation Failed !!!",
          }));
        }
      }
    } catch (error) {
      console.log(
        "post Creation Failed",
        error,
        error?.response?.status,
        error?.response?.data
      );
      setIsLoading(false);
      setIsResponseModal((prev) => ({
        ...prev,
        visible: true,
        message: error?.response?.data?.message || "Post Creation Failed !!!",
      }));
    }
  };

  return (
    <ScrollView
      className="p-4 rounded-t-2xl bg-gray-50"
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      keyboardShouldPersistTaps="always"
    >
      {isLoading && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={isResponseModal.visible}
        onRequestClose={closeResponseModal}
        bodyText={isResponseModal.message}
      ></ResponseModal>

      <View className="max-w-screen-lg w-full h-full">
        <View className="flex max-w-screen-lg w-full flex-col justify-center items-center">
          {/* ------------------------Create Group-Tab----------------------------------- */}
          <>
            <TouchableOpacity
              className="w-full flex"
              onPress={() => {
                setIsModalVisible(true);
              }}
            >
              <View className=" w-full flex p-4 item-center bg-app-a border border-gray-300 rounded-md">
                <TouchableOpacity className="flex-row justify-end items-center">
                  {/* {selectedImage != "" && selectedImage != null && (
                    <View className="mr-10">
                      <Pressable
                        onPress={() => setSelectedImage(null)}
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          zIndex: 9999,
                          backgroundColor: "white",
                        }}
                        className={`w-3 rounded-full justify-center items-center`}
                      >
                        <Text style={{ fontSize: 10, fontWeight: "600" }}>
                          X
                        </Text>
                      </Pressable>
                      <Image
                        // source={require("../../../assets/user.jpg")}
                        source={{ uri: selectedImage }}
                        className="w-8 h-8 rounded-lg"
                      />
                    </View>
                  )} */}
                  <Pressable
                    onPress={() =>
                      postDescription == "" ? null : CreatePostForGroup()
                    }
                    className={`px-2 py-1 flex-center items-center ${
                      postDescription == ""
                        ? "bg-gray-100 border border-gray-600"
                        : "bg-green-100 border border-green-600"
                    } rounded-lg`}
                  >
                    <Font
                      className={`text-sm ${
                        postDescription == ""
                          ? "text-gray-600"
                          : "text-green-600"
                      }`}
                    >
                      Post
                    </Font>
                  </Pressable>
                </TouchableOpacity>
                <View className="p-2 flex-row">
                  <View className="flex-row mr-2.5">
                    <Image
                      source={require("../../../assets/user.jpg")}
                      className="w-8 h-8 rounded-full"
                    />
                  </View>
                  <View className="flex-col flex-1">
                    <TouchableOpacity className="w-full">
                      <TextInput
                        className="w-full p-2 border border-gray-300 rounded-2xl"
                        placeholder="Write something..."
                        placeholderTextColor="#94a3b8"
                        multiline={true}
                        numberOfLines={4}
                        value={postDescription}
                        style={{ outline: "none" }}
                        onChangeText={(e) => setIsPostDescription(e)}
                      />
                    </TouchableOpacity>
                    <View className="w-full mt-2">
                      <TextInput
                        className="w-full p-2 border border-gray-300 rounded-2xl"
                        placeholder="https link to external url"
                        placeholderTextColor="#94a3b8"
                        style={{ outline: "none" }}
                        value={postExternalURL}
                        onChangeText={(e) => setPostExternalURL(e)}
                        
                      />
                    </View>
                  </View>
                </View>
              </View>
              {/* {selectedImage == "" ||
                (selectedImage == null && ( */}
              <View className=" flex-row p-2 w-full justify-center item-center ">
                <Pressable
                  onPress={openFilePicker}
                  className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
                >
                  <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="flex justify-center items-center">
                      <RemixIcon
                        name="ri-file-text-fill"
                        size={16}
                        color={colors.app.a4}
                        className="mr-2 lg:mr-4 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:ml-4 text-sm">File</Font>
                  </View>
                </Pressable>
                <Pressable
                  onPress={openGallery}
                  className="py-1 px-2 ml-2 mr-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
                >
                  <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="mr-2 lg:mr-4 flex justify-center items-center">
                      <RemixIcon
                        name="ri-image-fill"
                        size={16}
                        color={colors.app.b4}
                        className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:ml-4 text-sm">Gallery</Font>
                  </View>
                </Pressable>
                <Pressable
                  // onPress={takePicture}
                  onPress={openCamera}
                  className="py-1 px-2 lg:px-10 flex-row justify-center item-center bg-app-a rounded-full border border-gray-300"
                >
                  <View className="lg:w-full flex-row lg:justify-between lg:items-center">
                    <View className="mr-2 lg:mr-4 flex justify-center items-center">
                      <RemixIcon
                        name="ri-camera-fill"
                        size={16}
                        color={colors.app.c4}
                        className="mr-1 w-4 h-4 lg:w-6 lg:h-6 flex-shrink-0"
                      />
                    </View>
                    <Font className="lg:ml-4 text-sm">Camera</Font>
                  </View>
                </Pressable>
              </View>
              {/* ))} */}
              {selectedFile != "" && selectedFile != null && (
                <View className="w-full  h-16 mb-5 bg-gray-200 rounded-md">
                  <Pressable
                    onPress={() => {
                      setSelectedFile(null);
                      setSelectedFileObj(null);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 9999,
                      backgroundColor: "white",
                    }}
                    className={`w-5 h-5 rounded-full justify-center items-center`}
                  >
                    <Text style={{ fontSize: 14, fontWeight: "600" }}>X</Text>
                  </Pressable>
                  <View className="flex-col h-full items-center justify-center">
                    <RemixIcon
                      name="ri-file-text-fill"
                      size={18}
                      color={colors.app.a4}
                      className=" w-5 h-5 lg:w-8  flex-shrink-0 mb-2"
                    />
                    <Text
                      className="flex items-center justify-center"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      {selectedFileName}
                    </Text>
                  </View>
                  {/* <Image+
                    source={{ uri: selectedImage }}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: "contain" }}
                  /> */}
                </View>
              )}
              {selectedImage != "" && selectedImage != null && (
                <View 
                className="w-full h-56"
                // style={{ width: "100%", 
                // aspectRatio: imageDimensions.width / imageDimensions.height
                //  }}
                 >
                  <Pressable
                    onPress={() => {
                      setSelectedImage(null);
                      setSelectedImageObj(null);
                    }}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 9999,
                      backgroundColor: "#EF1211FF",
                    }}
                    className={`w-5 h-5 rounded-full justify-center items-center `}
                  >
                    <Text style={{ fontSize: 14, fontWeight: "600", color:"white"}}>X</Text>
                  </Pressable>
                  <Image
                    source={{ uri: selectedImage }}
                    className="rounded-lg"
                    style={{
                      width: "100%",
                      height: "100%",
                      resizeMode: "contain", // Maintains aspect ratio
                    }}
                  />
                </View>
              )}
            </TouchableOpacity>
          </>

          <>
            {/* <View className="flex-row p-12 w-full item-center bg-app-a">
              <View className="mr-2 -ml-8">
                <Image
                  source={require("../../../assets/user.jpg")}
                  className="w-10 h-10 rounded-full"
                />
              </View>

              <View className="flex-1 w-full">
                <View className="w-full flex-row justify-between items-center">
                  <Font className="text-wrap mr-2">
                    Description lorem ipsum dolor sit amet bla slo are asli whey
                    lorem dolor siu
                  </Font>
                  <View className="p-2 flex-row justify-end items-center">
                    <Pressable className="px-2 py-1 flex-center items-center bg-green-100 border border-green-600 rounded-lg">
                      <Font className="text-sm text-green-600">Post</Font>
                    </Pressable>
                  </View>
                </View>
                <View className="w-full h-56">
                  <Image
                    source={require("../../../assets/wallPost.jpg")}
                    className="w-full h-full rounded-lg"
                    // style={{ resizeMode: 'cover',}}
                  />
                </View>
              </View>
            </View> */}
          </>
        </View>

        {/* {isModalVisible && <CreateGroupPostModal />} */}

        {Platform.OS == "web" ? <></> : <Footer navigation={navigation} />}
      </View>
      {showCameraView ? (
        <CameraCustomView
          onImageCapture={(result) => {
            let path =
              Platform.OS === "ios"
                ? result?.uri?.replace("file:///", "file:/")
                : result?.uri;
            setSelectedImage(result.uri);
            if (result.uri !== null) {
              fileURLtoBlob(path)
                .then((data) => {
                  result.blob = data;
                  setSelectedImageObj(result);
                })
                .catch((error) => {
                  console.log("fileURLtoBlob-error", error);
                });
            }
            setShowCameraView(false);
            setSelectedImage(result?.uri);
            setSelectedImageObj(result);
          }}
          onRequestClose={() => {
            setShowCameraView(false);
          }}
          onDismiss={() => {
            setShowCameraView(false);
          }}
        />
      ) : (
        <></>
      )}
    </ScrollView>
  );
}
