import React from "react";
import {
  Image,
  View,
  Pressable,
  Platform,
  ImageBackground,
} from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input, Switcher } from "../../components/input";
import { ScrollView } from "react-native";
import useInputHandler from "../../utils/useInputHandler";
import { password_format } from "../../constants/regex";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import ResponseModal from "../../components/general/modals/ResponseModal";

export default function UpdatePassword({ navigation, route }) {
  const {
    currentValue: passwordValue,
    onBlurHandler: onPasswordBlurHandler,
    onValueChangeHandler: onPasswordChangeHandler,
    hasError: passwordHasError,
  } = useInputHandler({
    validateFunction: (value) => {
      if (value?.match(password_format)) {
        if (confPassValue) onConfPassChangeHandler(confPassValue);
        return "";
      }
      return "Password should have at least - Upper Case & LowerCase Letter, Symbol, Number and Minimum 8 Characters";
    },
  });
  const [showAPIResponse, setShowAPIResponse] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const hash = route.params.urlHash;

  const {
    currentValue: confPassValue,
    onBlurHandler: onConfPassBlurHandler,
    onValueChangeHandler: onConfPassChangeHandler,
    hasError: confPassHasError,
  } = useInputHandler({
    validateFunction: (value) => {
      if (value === passwordValue) {
        return "";
      }
      return "Password does not match";
    },
  });

  const updatePassword = async () => {
    // console.log("start");
    if (!(passwordValue && !passwordHasError)) {
      return;
    }
    try {
      const res = await axiosInstance.post(apiURIV2 + "/auth/reset-password", {
        hash: hash,
        new_password: passwordValue,
      });
      if (res.status === 200) {
        setShowAPIResponse({
          visible: true,
          bodyText: `${res?.status}: ${res.data?.message}`,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });

            navigation.navigate("update-password-successful");
          },
          dismissText: "Okay",
        });
      } else {
        setShowAPIResponse({
          visible: true,
          bodyText: `${res?.status}: ${res.data?.message}`,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });

            // navigation.replace("confirm-email-sent");
          },
          dismissText: "Okay",
        });
        console.log("update-password, update pass res error : ", res.data);
      }
    } catch (error) {
      setShowAPIResponse({
        visible: true,
        bodyText: `${error.response?.status}: ${error.response.data?.message}`,
        onRequestClose: () => {
          setShowAPIResponse({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });

          // navigation.replace("confirm-email-sent");
        },
        dismissText: "Okay",
      });
      console.log("update-password, update pass error : ", error);
    }
  };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              ></Image>
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Set new password
              </Font>
            </View>
            <View className="w-full mb-2">
              <Input
                type="password"
                label="Password"
                placeholder="************"
                value={passwordValue}
                setValue={onPasswordChangeHandler}
                onBlur={onPasswordBlurHandler}
                note={passwordHasError}
                invalid={!!passwordHasError}
              />
            </View>
            <View className="w-full mb-4">
              <Input
                type="password"
                label="Confirm Password"
                placeholder="************"
                value={confPassValue}
                setValue={onConfPassChangeHandler}
                onBlur={onConfPassBlurHandler}
                note={confPassHasError}
                invalid={!!confPassHasError}
              ></Input>
            </View>
            <View className="w-full my-2">
              <Button
                type="primary"
                label="Update password"
                onPress={updatePassword}
              ></Button>
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
      <ResponseModal
        visible={showAPIResponse.visible}
        onRequestClose={showAPIResponse.onRequestClose}
        bodyText={showAPIResponse.bodyText}
        dismissText={showAPIResponse?.dismissText}
      />
    </View>
  );
}
