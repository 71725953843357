import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Modal, Pressable, ScrollView, View } from "react-native";
import { Button } from "../input";
import { Font } from "../font";
import { useNavigation } from "@react-navigation/native";
import { useCallback } from "react";

export default function CheckoutForm({
  show = true,
  isReloadedPage,
  onRequestClose = () => null,
  onSuccess = () => null,
  onError = () => null,
  onCheckoutLoad = () => null,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const navigation = useNavigation();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const extractError = useCallback((error) => {
    return {
      type: error.type,
      code: error.code,
      decline_code: error.decline_code,
      message: error.message,
      doc_url: error.doc_url,
    };
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent, error }) => {
        if (error) {
          onError(extractError(error), paymentIntent);
        }
        // else {
        //   navigation.setParams({
        //     payment_intent: paymentIntent.id,
        //     payment_intent_client_secret: paymentIntent.client_secret,
        //     redirect_status: paymentIntent.status,
        //   });
        // }
        if (error?.payment_intent || paymentIntent) {
          navigation.setParams({
            payment_intent: error?.payment_intent?.id || paymentIntent?.id,
            payment_intent_client_secret:
              error?.payment_intent?.client_secret ||
              paymentIntent?.client_secret,
            redirect_status:
              error?.payment_intent?.status || paymentIntent?.status,
          });
        }
        if (!isReloadedPage && paymentIntent?.status !== "succeeded") {
          return;
        }

        switch (paymentIntent.status) {
          case "succeeded":
            onSuccess(paymentIntent);
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was interrupted, please try again.");
            break;
          case "canceled":
            setTimeout(() => {
              navigation.setParams({
                payment_intent: undefined,
                payment_intent_client_secret: undefined,
                redirect_status: undefined,
              });
              onError(
                extractError({
                  ...(error || {
                    type: "cancelled",
                    code: "canceled_by_stripe",
                    message:
                      "Your payment has been canceled. please re-initiate again",
                  }),
                  payment_intent: paymentIntent,
                })
              );
            }, [3000]);
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe, isReloadedPage]);

  useEffect(() => {
    if (message) setTimeout(() => setMessage(""), 10000);
  }, [message]);

  const handleSubmit = async (e) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (error.payment_intent || paymentIntent) {
        navigation.setParams({
          payment_intent: error?.payment_intent?.id || paymentIntent.id,
          payment_intent_client_secret:
            error?.payment_intent?.client_secret || paymentIntent.client_secret,
          redirect_status:
            error?.payment_intent?.status || paymentIntent.status,
        });
      }
      if (error.type === "card_error") {
        setMessage(
          `${error.message}\n\n${JSON.stringify(
            {
              type: error.type,
              code: error.code,
              decline_code: error.decline_code,
            },
            undefined,
            1
          )}${error.doc_url ? `\n\nLearn more: ${error.doc_url}` : ""}`
        );
      } else if (error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage(
          "An unexpected error occurred.\n",
          error?.code,
          "\n",
          error?.message,
          "\n",
          error?.type
        );
        onError(extractError(error), paymentIntent);
      }
    } else {
      onSuccess(paymentIntent);
    }

    setIsLoading(false);
  };

  return (
    <Modal
      visible={show}
      animationType="slide"
      accessibilityElementsHidden={true}
      aria-hidden={true}
      accessibilityViewIsModal={true}
      aria-modal={true}
      onRequestClose={onRequestClose}
      transparent={true}
      presentationStyle="formSheet"
    >
      <Pressable
        className="flex-1 bg-app-d3 absolute w-full h-full"
        onPress={onRequestClose}
      />
      <View className="bg-white z-auto p-4 my-auto rounded-lg align-middle justify-items-stretch justify-center min-w-max w-full max-w-[90%] lg:max-w-lg self-center justify-self-stretch max-h-[90%]">
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ padding: 8 }}
        >
          <PaymentElement
            options={{ layout: "tabs" }}
            className="mb-2"
            onLoaderStart={() => setIsLoading(true)}
            onReady={() => {
              setIsLoading(false);
              onCheckoutLoad();
            }}
            onLoadError={({ error }) => {
              setIsLoading(false);
              setMessage(error);
            }}
          />
          {/* Show any error or success messages */}
          <Font className="text-app-e my-2 leading-4">
            {!!message ? message : ""}
          </Font>
          <Button
            type="primary"
            className="mt-2 lg:w-60 self-center"
            onPress={handleSubmit}
            waitState={!stripe || !elements || isLoading}
            disabled={!stripe || !elements || isLoading}
            loaderWithText={!stripe || !elements ? "Please Wait" : undefined}
          >
            Pay now
          </Button>
        </ScrollView>
      </View>
    </Modal>
  );
}
