import React, { useCallback, useEffect, useState } from "react";
import {
  Platform,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import RemixIcon from "react-native-remix-icon";
import colors from "../../../colors";
import { Card } from "../../components/general/cards/card";
import { Font } from "../../components/font";
import { Footer } from "../../components/footer";
import UploadPicture from "../../components/uploadpicture";
import { Button, Input } from "../../components/input";
import { AuthContext } from "../../contexts/auth-context";
import { apiURIV2 } from "../../constants";
import { getUserProfile, isSignedIn } from "../../actions/auth-actions";
import { createCrossPlatformFormData, fileURLtoBlob } from "../../utils/encDec";
import axiosInstance from "../../constants/axios-config";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { catchAPIErrorText } from "../../utils/error-handler";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import { useSharedContext } from "../../contexts/shared-context";
import PhoneInput from "react-native-phone-input";

export default function UpdateProfile({ navigation, route }) {
  const { user, setUser } = React.useContext(AuthContext);
  const [deleteModal, setIsDeleteModal] = React.useState(false);
  const { setTemporaryData } = useSharedContext();
  const [email, setEmail] = useState(user.email);
  const [first_name, setFirstName] = useState(user.first_name);
  const [last_name, setLastName] = useState(user.last_name);
  const [company_name, setCompanyName] = useState(user.company_name);
  const [phone, setPhone] = useState(user.phone);
  const [profile_image_url, setProfile_image_url] = useState(
    user?.profile_image_url
      ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
          user.profile_image_url
      : null
  );
  const [removePicture, setRemovePicture] = useState(false);

  const [imagetoUpload, selectImageToUpload] = useState(null);
  const [about, setAbout] = useState(user.about);
  const [gettingUserInfo, setGettingUserInfo] = useState();
  const [showResponseModal, setShowResponseModal] = useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });

  const RemovePictureApi = async () => {
    try {
      const userToken = await isSignedIn();
      setIsDeleteModal(false);
      const res = await axiosInstance.delete(
        apiURIV2 + "/users/me/profile-image",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            // "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status == 204) {
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: res.data?.message || "Profile Picture Removed !",
        });
        selectImageToUpload(""); // Clear selected image
        setProfile_image_url(""); // Clear profile image URL
        setRemovePicture(true);
        setTimeout(() => {
          GetUserData();
        }, 3000);
      }
    } catch (error) {
      console.log(error, "delete pic error");
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(error, "Something went wrong!"),
      });
    }
  };

  const GetUserData = useCallback(async () => {
    setGettingUserInfo(true);
    getUserProfile()
      .then((res) => {
        if (res.status === 200) {
          const updated = res.data?.data || {};
          setUser({ ...user, ...updated });
          setEmail(updated.email);
          setFirstName(updated.first_name);
          setLastName(updated.last_name);
          setCompanyName(updated.company_name);
          setPhone(updated.phone);
          // updated.profile_image_url
          //   ? setProfile_image_url(
          //       "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
          //         updated.profile_image_url
          //     )
          //   : setProfile_image_url("");
          setProfile_image_url(
            updated.profile_image_url
              ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                  updated.profile_image_url
              : null
          );
          setAbout(updated.about);
        }
      })
      .catch((error) => {
        console.log("Error in Getting User Info", error);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(error, "Something went wrong!"),
        });
      })
      .finally(() => {
        setGettingUserInfo(false);
      });
  });

  useEffect(() => {
    GetUserData();
  }, []);

  const CheckValidation = () => {
    if (first_name == "") {
      alert("First Name is required");
      return;
    } else if (last_name == "") {
      alert("Last Name is required");
      return;
    } else if (company_name == "") {
      alert("Company is required");
      return;
    } else if (email == "") {
      alert("Email is required");
      return;
    } else if (phone == "") {
      alert("Phone is required");
      return;
    } else {
      UpdateProfile();
    }
  };
  const UpdateProfile = async () => {
    let updateObj = {
      email: email,
      phone: phone,
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      about: about,
      profile_image: imagetoUpload ? imagetoUpload : undefined,
    };

    const formData = createCrossPlatformFormData({
      blobDataKeys: ["profile_image"],
      data: updateObj,
    });
    const userToken = await isSignedIn();
    axiosInstance
      .patch(`${apiURIV2}/users/me`, formData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res?.status == 200) {
          if (res?.data?.verification_entity == "phone") {
            setTemporaryData({ mobileSMSOTP: res?.data?.otp });
            navigation.replace("verifyOtp", {
              hash: res?.data?.hash,
              redirectTo: "app",
            });
          } else {
            setShowResponseModal({
              visible: true,
              onRequestClose: () => {
                setShowResponseModal({
                  visible: false,
                  onRequestClose: () => null,
                  bodyText: "",
                });
              },
              bodyText: res.data?.message || "Profile Updated!",
            });
          }
          GetUserData();
        }
      })
      .catch((e) => {
        console.log("error in update profile", e);
        setShowResponseModal({
          visible: true,
          onRequestClose: () => {
            setShowResponseModal({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: catchAPIErrorText(e, "Something went wrong!"),
        });
      });
  };
  const onChangeProfileImage = async (imageObject) => {
    try {
      let path =
        Platform.OS === "ios"
          ? imageObject.uri.replace("file:///", "file:/")
          : imageObject.uri;
      fileURLtoBlob(path).then((data) => {
        imageObject.blob = data;
        // setRemovePicture(false)
        selectImageToUpload(imageObject);
      });
    } catch (error) {
      console.log(
        "Update Error:",
        error,
        error?.response?.status,
        error?.response?.data
      );
      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(error, "Something went wrong!"),
      });
    }
  };

  const resetRemovePicture = () => {
    setRemovePicture(false); // Reset the remove picture flag when a new image is uploaded
  };

  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : (
            <RefreshControl
              refreshing={gettingUserInfo}
              onRefresh={GetUserData}
            />
          )
        }
        keyboardShouldPersistTaps="always"
      >
        <ConfirmModal
          visible={deleteModal}
          onCancel={() => setIsDeleteModal(false)}
          onConfirm={RemovePictureApi}
          confirmText={"Delete"}
          bodyText={"Are you sure you want to Remove the Profile Picture ?"}
          BodyComponent={false}
        ></ConfirmModal>
        <View className=" max-w-screen-lg w-full mx-auto">
          <View className="my-5 hidden lg:flex">
            <Font className="text-lg font-semibold text-app-d">
              Update profile
            </Font>
          </View>
          <View className="flex-col lg:flex-row max-w-screen-lg w-full">
            <View className=" w-full lg:w-4/6 p-0 lg:pr-4">
              <Card title="Update Profile">
                <View className="">
                  <View className="p-4 items-center justify-center">
                    <UploadPicture
                      onImagePick={(data) => onChangeProfileImage(data)}
                      source={profile_image_url}
                      removePicture={removePicture}
                      resetRemovePicture={resetRemovePicture}
                    ></UploadPicture>
                  </View>
                  {imagetoUpload !== "" ? (
                    <View className="w-full px-4 pt-4 pb-1 flex-row justify-center">
                      {/* <Pressable
                        onPress={() => {
                          selectImageToUpload(""); // Clear selected image
                          setProfile_image_url(""); // Clear profile image URL
                          setRemovePicture(true);
                        }}
                        className="p-2 items-center justify-center bg-red-500 w-[50%] md:w-[30%] rounded-md"
                      >
                        <Text className="text-app-a">Remove Picture</Text>
                      </Pressable> */}
                      <Button
                        className="w-[50%] lg:w-[30%]"
                        onPress={() => {
                          setIsDeleteModal(true);
                        }}
                        type="primary"
                        label="Remove Picture"
                      ></Button>
                    </View>
                  ) : (
                    <></>
                  )}
                  <View className="flex-row flex-wrap mb-4">
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="First Name"
                        value={first_name}
                        setValue={(value) => setFirstName(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Last Name"
                        value={last_name}
                        setValue={(value) => setLastName(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Company Name"
                        value={company_name}
                        setValue={(value) => setCompanyName(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <Input
                        type="text"
                        label="Email"
                        value={email}
                        setValue={(value) => setEmail(value)}
                      ></Input>
                    </View>
                    <View className="w-full lg:w-1/2 px-4 py-1">
                      <View className="w-full h-8 items-center flex-row">
                        <Font className="flex-1  text-app-d1" weight={600}>
                          Phone Number
                        </Font>
                      </View>
                      <View className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a px-2 flex-row  justify-center items-center">
                        <PhoneInput
                          // ref={phoneInputRef}
                          className="  flex-row "
                          // initialCountry="us"
                          initialValue={phone}
                          value={phone}
                          onChangePhoneNumber={(text) => {
                            setPhone(text);
                            // Example validation logic
                            // setIsValid({ ...isValid, phoneNumber: text.length >= 10, });
                          }}
                          onPressFlag={() => null}
                          textStyle={styles.textInput}
                          textProps={{
                            placeholder: "Enter a phone number...",
                          }}
                          style={styles.phoneInput}
                        />
                      </View>
                    </View>
                    <View className="w-full px-4 py-1">
                      <Input
                        type="longtext"
                        label="About"
                        value={about}
                        setValue={(value) => setAbout(value)}
                      ></Input>
                    </View>
                    <View className="w-full px-4 pt-4 pb-1 flex-row justify-center">
                      <Button
                        className="w-full lg:w-1/2"
                        onPress={() => CheckValidation()}
                        type="primary"
                        label="Save"
                      ></Button>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
            <View className=" w-full lg:w-2/6 p-0 lg:pl-4">
              <Card title="SMBEx">
                <View className="flex-1 w-full p-4 pt-0">
                  <Font>
                    We collaboratively administrate turnkey channels where
                    kingdom businesses can become virtual retailers. We provide
                    scalable metrics, proactive e-services and objective metrics
                    for small businesses to thrive in any economic environment.
                  </Font>
                </View>
              </Card>
              <Card title="Find us there">
                <View className="flex-1 w-full p-4 pt-0">
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="phone-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Phone number</Font>
                      <Font className="text-app-e">1 877 963-5797</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="global-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Website</Font>
                      <Font className="text-app-e">www.SMBEx.com</Font>
                    </View>
                  </View>
                  <View className="flex-row items-center">
                    <View className="h-16 w-16 items-center justify-center">
                      <RemixIcon
                        name="mail-fill"
                        size={40}
                        color={colors.app.d1}
                      ></RemixIcon>
                    </View>
                    <View>
                      <Font>Email Address</Font>
                      <Font className="text-app-e">office@SMBEx.com</Font>
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </View>
          <Footer navigation={navigation}></Footer>
        </View>
      </ScrollView>
      {showResponseModal.visible && (
        <ResponseModal
          visible={showResponseModal.visible}
          onRequestClose={showResponseModal.onRequestClose}
          bodyText={showResponseModal.bodyText}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  phoneInput: {
    flex: 1,
    justifyContent: "center",
  },
  textInput: {
    padding: 10,
    flex: 1,
    height: 40, // Align with parent container
    fontSize: 16,
    color: "#000", // Default text color
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
});
