import {
  Image,
  Modal,
  Platform,
  Pressable,
  ScrollView,
  Share,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React from "react";
import CommentSection from "./general/commentSection";
import CreateGroupPostModal from "../screens/vendor/create-post-modal";
import { Font } from "./font";
import RemixIcon from "react-native-remix-icon";
import moment from "moment";
import * as Linking from "expo-linking";
import colors from "../../colors";
import ConfirmModal from "./general/modals/ConfirmModal";
import { PleaseWaitModal } from "./modal";
import ResponseModal from "./general/modals/ResponseModal";
import { deleteAPost, LikePostApi, ShareAPost } from "../actions/auth-actions";
import { cloneDeep } from "lodash";
import { useVendorFeedContext } from "../contexts/vendor-feed-context";
import { useNavigation } from "@react-navigation/native";
import OptionsModal from "./general/modals/OptionsModal";

const GroupPosts = ({
  // key,
  item,
  i,
  name,
  groupId,
  getGroupPosts,
  groupPost,
  setGroupPost,
}) => {
  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();
  const navigation = useNavigation();
  const [showMoreDesc, setShowMoreDesc] = React.useState(false);
  const [isPostId, setIsPostId] = React.useState();
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [isEditPostModal, setIsEditPostModal] = React.useState(false);
  const [isCommentSectionVisible, setIsCommentSectionVisible] =
    React.useState(false);
  const { tabStatus, setTabStatus } = useVendorFeedContext();
  const closeMenu = () => {
    setIsMenuVisible(false);
  };
  const closeEditPostModal = () => setIsEditPostModal(false);
  const openMenu = () => {
    // if (buttonRef.current) {
    //   buttonRef.current.measure((x, y, width, height, pageX, pageY) => {
    //     setButtonPosition({ left: pageX - 100, top: pageY + height - 30 });
    //   });
    // }
    setIsMenuVisible(true);
  };

  const [isPleaseWaitModal, setIsPleaseWaitModal] = React.useState(false);
  const [responseModal, setResponseModal] = React.useState({
    visible: false,
    message: "",
    DismissText: "",
  });
  const [deleteModal, setIsDeleteModal] = React.useState(false);

  const closeDeleteModal = () => {
    setIsDeleteModal(false);
  };
  const closeComment = () => {
    setIsCommentSectionVisible(false);
  };
  const closeResponseModal = () => {
    setResponseModal((prev) => ({ ...prev, visible: false }));
  };

  const deletePost = async () => {
    try {
      setIsDeleteModal(false);
      setIsPleaseWaitModal(true);
      let res = await deleteAPost(groupId, isPostId);
      // console.log(res, "res check for delete posts");
      // console.log(isPostId, "isPostId");

      if (res.status === 204) {
        setGroupPost((prevList) =>
          prevList.filter((post) => post.id !== isPostId)
        );
        setIsPleaseWaitModal(false);
        setResponseModal({
          visible: true,
          message: "Post Deleted Successfully !!!",
          DismissText: "Dismiss",
        });
        // setGroupPost(res.data.posts);
        // console.log(res.data, "response check success for Like posts");
      } else {
        setResponseModal({
          visible: true,
          message: "Something went wrong !!!",
          DismissText: "Dismiss",
        });
        setIsPleaseWaitModal(false);
        console.log(res.data, "response check failed for delete posts");
      }
    } catch (error) {
      console.log("Error Getting share : ", error.response);
    }
  };

  const sharePostApi = async (data, postId, index) => {
    try {
      const result = await (Platform.OS === "web"
        ? window.navigator.share({
            text: data.message,
            url: data.url,
            title: data.title,
          })
        : Share.share({
            message: data.message,
            url: data.url,
            title: data.title,
          }));
      if (result?.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
          try {
            let res = await ShareAPost(groupId, postId);
            if (res.status === 201) {
              setGroupPost((data) => {
                data[index].shares++;

                return cloneDeep(data);
              });
              // setGroupPost(res.data.posts);
              // console.log(res.data, "response check success for share posts");
            } else {
              console.log(res.data, "response check failed for share posts");
            }
          } catch (error) {
            console.log("Error Getting share : ", error.response);
          }
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
        console.log("Share action was dismissed.");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const LikeDisLikePost = async (postId, isLiked, index) => {
    try {
      let res = await LikePostApi(groupId, postId, isLiked);
      console.log(res, "res check for Like posts");

      if (res.status === 201) {
        setGroupPost((data) => {
          const is_liked = isLiked;
          data[index].is_liked = is_liked ? false : true;
          if (is_liked) {
            data[index].likes--;
          } else {
            data[index].likes++;
          }
          return cloneDeep(data);
        });
        // setGroupPost(res.data.posts);
        // console.log(res.data, "response check success for Like posts");
      } else {
        console.log(res, "response check failed for Like posts");
        console.log(res.data, "response check failed for Like posts data");
      }
    } catch (error) {
      console.log("Error Getting share : ", error.response);
    }
  };

  return (
    <View className="p-2 w-full flex justify-center items-center lg:justify-start lg:items-start bg-app-a border-y border-t-gray-300">
      {isMenuVisible ? (
        <OptionsModal
          onPressForFirstOption={() => {
            setIsEditPostModal(true);
            closeMenu();
          }}
          firstOptionSymbol={"ri-pencil-fill"}
          firstOptionLabel={"Edit"}
          secondOptionSymbol={"delete-bin-line"}
          secondOptionLabel={"Remove"}
          onPressForSecondOption={() => {
            setIsDeleteModal(true);
            closeMenu();
          }}
          isMenuVisible={isMenuVisible}
          closeMenu={closeMenu}
          // group_role={member.group_role}
          setIsDeleteModal={setIsDeleteModal}
        />
      ) : (
        // <Modal
        //   visible={isMenuVisible}
        //   transparent={true}
        //   onRequestClose={closeMenu}
        // >
        //   <TouchableWithoutFeedback onPress={closeMenu}>
        //     <View
        //       style={{
        //         flex: 1,
        //         justifyContent: "center",
        //         alignItems: "center",
        //       }}
        //       className="w-full h-full justify-center items-center bg-black/50"
        //     >
        //       <TouchableWithoutFeedback>
        //         <View
        //           style={{
        //             // position: "absolute",
        //             // left: buttonPosition.left,
        //             // top: buttonPosition.top,
        //             backgroundColor: "white",
        //             padding: 10,
        //             borderRadius: 10,
        //             width: 120,
        //             shadowColor: "#000",
        //             shadowOffset: {
        //               width: 0,
        //               height: 2,
        //             },
        //             shadowOpacity: 0.25,
        //             shadowRadius: 3.84,
        //             elevation: 5,
        //           }}
        //         >
        //           <ScrollView>
        //             <Pressable
        //               onPress={() => {
        //                 setIsEditPostModal(true);
        //                 closeMenu();
        //               }}
        //               className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
        //             >
        //               <Font className="">Edit Post</Font>
        //             </Pressable>
        //             <View
        //               style={{
        //                 borderWidth: 0.4,
        //                 marginVertical: 4,
        //               }}
        //             ></View>
        //             <Pressable
        //               onPress={() => {
        //                 setIsDeleteModal(true);
        //                 closeMenu();
        //               }}
        //               className=" pr-0 flex-1 item center bg-app-a rounded-lg shadow-sm"
        //             >
        //               <Font className="text-app-e">Delete Post</Font>
        //             </Pressable>
        //           </ScrollView>
        //         </View>
        //       </TouchableWithoutFeedback>
        //     </View>
        //   </TouchableWithoutFeedback>
        // </Modal>
        <></>
      )}

      <ConfirmModal
        visible={deleteModal}
        onCancel={closeDeleteModal}
        onConfirm={deletePost}
        confirmText={"Delete"}
        bodyText={"Are you sure you want to delete Post ?"}
        BodyComponent={false}
      ></ConfirmModal>
      {isPleaseWaitModal && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={responseModal.visible}
        bodyText={responseModal.message}
        onRequestClose={closeResponseModal}
      ></ResponseModal>
      <View className="w-full px-4 flex-row justify-between items-center ">
        <View className="flex-row justify-start items-center">
          <View className="mr-2">
            <Image
              source={require("../../assets/user.jpg")}
              className="w-10 h-10 rounded-full"
            />
          </View>
          <View className="flex justify-start item-center">
            <Font className="text-sm text-app-d font-bold">{name}</Font>
            <View className="flex-row justify-start items-center">
              {/* <Font className="text-xs text-gray-500">
                                  Owner
                                </Font> */}
              {/* <Font className="mr-2 ml-2 flex items-center text-xs font-extrabold self-center">
                                  .
                                  </Font> */}
              <Font className="text-xs text-gray-500">
                {timeAgo(item?.created_at)}
              </Font>
            </View>
          </View>
        </View>
        <Pressable
          className="mr-2 w-4 h-4 lg:w-10 lg:h-10 self-center"
          onPress={() => {
            console.log("pressed !");

            setIsPostId(item?.id);
            openMenu();
          }}
          // ref={buttonRef}
        >
          <RemixIcon
            className="-mt-1"
            name="ri-more-fill"
            color={"#000000"}
            size="18"
          />
        </Pressable>
      </View>
      <View className="px-4 w-full">
        {item?.description ? (
          <View className="py-2 w-full">
            <Font
              className="text-app-d text-sm"
              numberOfLines={showMoreDesc ? 0 : 2}
            >
              {item?.description}
            </Font>
          </View>
        ) : (
          <></>
        )}
        {item?.cover_image_url !== null && (
          <View className="w-full h-56 lg:h-96 py-1 flex-col flex">
            <Image
              resizeMode="cover"
              source={{
                uri:
                  "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                  item?.cover_image_url,
              }}
              className="w-full h-full rounded-lg"
            />
          </View>
        )}
        {item?.external_link_url ? (
          <Pressable
            className="p-2 mb-0 w-full flex-row item-center justify-between"
            onPress={() =>
              item?.external_link_url
                ? Linking.openURL(
                    `${item?.external_link_url}`.replace(
                      /^(?!https?:\/\/)/,
                      "https://"
                    )
                  ).catch((e) => console.log("External URL error:", e))
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {item?.external_link_url}
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click on this link to visit
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Learn More</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
        {item?.attached_file_url ? (
          <Pressable
            className="p-2 mb-5 w-full flex-row item-center justify-between"
            onPress={() =>
              item?.attached_file_url
                ? Linking.openURL(
                    "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                      item?.attached_file_url
                  )
                : null
            }
          >
            <View className="flex justify-center items-center">
              <View className="flex item-center justify-between">
                <Font className="text-lg text-app-d">
                  {/* {item?.external_link_url} */}
                  Attached File
                </Font>
                <Font className="text-sm text-app-d">
                  {/* Headline copy here */}Click to Download file
                </Font>
              </View>
            </View>
            <View className="flex justify-center items-center">
              <View className="p-2 py-1 flex justify-center items-center bg-app-c border-2 border-gray-300 rounded-lg">
                <Font className="text-xs">Download</Font>
              </View>
            </View>
          </Pressable>
        ) : (
          <></>
        )}
      </View>
      <View className="px-4 py-2 w-full flex-row justify-between item-center border-b border-t border-y-gray-300 mt-1">
        <View className="flex-row justify-center item-center">
          <View className="z-10 h-8 w-8 bg-indigo-600 border-4 border-gray-300 rounded-full items-center justify-center">
            <RemixIcon name="ri-thumb-up-fill" color={colors.app.a} size="16" />
          </View>
          {/* <View className="-ml-2 h-8 w-8 bg-red-400 border-4 border-gray-300 rounded-full items-center justify-center">
            <RemixIcon name="ri-heart-fill" color={colors.app.a} size="18" />
          </View> */}
          <View className="flex justify-center items-center px-2">
            <Font className="text-base text-gray-500">{item?.likes}</Font>
          </View>
        </View>
        <View className="flex-row justify-around items-center">
          <Pressable
            onPress={() => {
              setIsCommentSectionVisible((prev) => !prev);
            }}
            className="mr-3 self-center"
          >
            <Font className="text-base text-gray-500">
              {item?.comments} Comments
            </Font>
          </Pressable>
          <Font className="text-base text-gray-500 self-center">
            {item?.shares} Shares
          </Font>
        </View>
      </View>
      <View className="py-2 w-full flex-row justify-around item-center">
        <Pressable
          onPress={() => LikeDisLikePost(item?.id, item?.is_liked, i)}
          className="flex-row item-center self-center p-1"
        >
          <RemixIcon
            name="ri-thumb-up-line"
            color={item?.is_liked ? colors.app.f : colors.app.d1}
            size="22"
          />
          <Font className="text-lg px-2 text-gray-600 leading-5">Like</Font>
        </Pressable>
        <Pressable
          onPress={() => {
            setIsPostId(item?.id);
            setIsCommentSectionVisible((prev) => !prev);
          }}
          className={`flex-row item-center self-center p-1 ${
            isCommentSectionVisible
              ? "bg-app-e3 rounded-md border-app-e border"
              : ""
          }`}
        >
          <RemixIcon name="ri-chat-4-line" color={colors.app.f4} size="22" />
          <Font className="text-lg px-2 text-gray-600 leading-5">Comment</Font>
        </Pressable>
        <Pressable
          onPress={() =>
            sharePostApi(
              {
                title: item.description,
                message: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${groupId}`,
                url: `https://smbexcustomer.wisdomtooth.tech/group-details?id=${groupId}`,
              },
              item?.id,
              i
            )
          }
          className="flex-row item-center self-center p-1"
        >
          <RemixIcon
            name="ri-share-forward-line"
            color={colors.app.f4}
            size="22"
          />
          <Font className="text-lg px-2 text-gray-600 leading-5">Share</Font>
        </Pressable>
        {isPostId == item?.id ? (
          <CreateGroupPostModal
            visible={isEditPostModal}
            onRequestClose={closeEditPostModal}
            postImage={
              item?.cover_image_url
                ? "https://smbexapi.wisdomtooth.tech/api/v2/media/" +
                  item?.cover_image_url
                : null
            }
            Description={item?.description}
            attachedFileURL={item?.attached_file_url}
            externalLinkURL={item?.external_link_url}
            id={item?.id}
            groupId={groupId}
            getGroupPosts={getGroupPosts}
            // selectedImgObj={selectedImageObj}
          />
        ) : (
          <></>
        )}
        {isCommentSectionVisible && isPostId == item?.id ? (
          <Modal
            visible={isCommentSectionVisible}
            transparent={true}
            onRequestClose={closeComment}
          >
            <TouchableWithoutFeedback onPress={closeComment}>
              <View className="w-full h-full bg-black/20 flex-col justify-center items-center px-4">
                <TouchableWithoutFeedback>
                  <View className="flex flex-row self-center justify-center items-center max-h-[70%] lg:max-h-[90%] lg:-mb-8 bg-transparent">
                    <CommentSection
                      // route={{ params: { slug: id } }}
                      id={item?.id}
                      groupId={groupId}
                      useId={true}
                      navigation={navigation}
                      setTabStatus={setTabStatus}
                      tabStatus={tabStatus}
                      onPostCommentSuccess={() => {
                        setGroupPost((posts) => {
                          const index = posts.findIndex(
                            (p) => p.id === item.id
                          );
                          if (index > -1) {
                            posts[index].comments++;
                          }
                          return [...posts];
                        });
                      }}
                      onDeleteCommentSuccess={() => {
                        setGroupPost((posts) => {
                          const index = posts.findIndex(
                            (p) => p.id === item.id
                          );
                          if (index > -1) {
                            posts[index].comments--;
                          }
                          return [...posts];
                        });
                      }}
                    />
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </TouchableWithoutFeedback>
          </Modal>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

export default GroupPosts;
