import React, { useCallback } from "react";
import { Platform, Pressable, View } from "react-native";
import { Font } from "../../font";
import { getFullDateTime } from "../../../utils/date-time-util";
import PlanStatusChip from "../status-chips/plan-status-chip";

const PlanCard = ({
  isFeatured,
  isDisabled = false,
  isSelected,
  isCurrentPlan = false,
  planTitle,
  planPrice,
  planSubtitle,
  planFeatures,
  onPress,
  buttonTitle,
  // showButton = true,
  status = "",
  nextRenewalDate = "",
  validity,
}) => {
  const [headerTextStyle, setHeaderTextStyle] = React.useState("text-app-d");
  const [borderStyle, setBorderStyle] = React.useState("border-app-c1");
  const [backgroundStyle, setBackgroundStyle] = React.useState("");
  const [headerBgStyle, setHeaderBgStyle] = React.useState("");
  // const [buttonStyle, setButtonStyle] = React.useState(
  //   "border-app-g bg-app-g3"
  // );
  // const [buttonTitleStyle, setButtonTitleStyle] = React.useState("text-app-g");
  React.useEffect(() => {
    setBorderStyle(
      isCurrentPlan
        ? "border-app-a"
        : isSelected || isFeatured
        ? "border-app-e"
        : "border-app-c"
    );
    setBackgroundStyle(
      isCurrentPlan
        ? "bg-app-d3"
        : isSelected || isFeatured
        ? "bg-app-a"
        : "bg-white"
    );
    setHeaderBgStyle(isSelected ? "bg-app-e" : "");
    setHeaderTextStyle(
      isCurrentPlan ? "" : isSelected ? "text-app-a" : "text-app-d"
    );
  }, [isCurrentPlan, isFeatured, isSelected]);

  const onPressHandler = useCallback(() => {
    if (isDisabled) return;
    return onPress();
  }, [isDisabled, onPress]);

  return (
    <Pressable
      className={`w-full flex-auto flex-col border rounded-lg overflow-hidden ${borderStyle} ${backgroundStyle}`}
      onPress={onPressHandler}
    >
      <View className={`w-full h-max ${headerBgStyle} px-4 py-3`}>
        <View className="flex-row flex-wrap justify-between items-center">
          <Font
            className={`text-lg font-semibold capitalize mr-2 ${headerTextStyle}`}
          >
            {planTitle}
          </Font>
          {(isCurrentPlan && status) || nextRenewalDate ? (
            <PlanStatusChip status={nextRenewalDate ? "scheduled" : status} />
          ) : (
            <></>
          )}
        </View>
        <View className="justify-center items-center">
          <Font className={`text-lg font-semibold ${headerTextStyle} w-full capitalize`}>
            {planPrice} / {validity}
          </Font>
        </View>
        {planSubtitle ? (
          <View className="justify-center items-center">
            <Font className={`mt-2 ${headerTextStyle}`}>{planSubtitle}</Font>
          </View>
        ) : null}
        {nextRenewalDate ? (
          <View className="flex-row flex-wrap pt-2">
            <Font
              className={`w-max ${
                Platform.OS === "web" ? "text-xs" : "text-sm"
              } ${headerTextStyle}`}
            >
              Effective on:{" "}
            </Font>
            <Font
              className={`w-max ${
                Platform.OS === "web" ? "text-xs" : "text-sm"
              } ${headerTextStyle}`}
            >
              {getFullDateTime(nextRenewalDate)}
            </Font>
          </View>
        ) : (
          <></>
        )}
      </View>
      <View className={`border-b ${isSelected ? "" : "mx-4"} ${borderStyle}`} />
      <View className="w-full p-4">
        {planFeatures?.length ? (
          planFeatures.map((feature, i) => (
            <View key={"pc" + i} className="flex-row">
              <Font className="mb-1 text-app-d1 mr-0.5">-</Font>
              <Font className="mb-1 text-app-d1">{feature}</Font>
            </View>
          ))
        ) : (
          <Font className="mb-1 text-app-d1 text-center align-middle">-</Font>
        )}
      </View>
      {/* {showButton && (
        <View className="p-4">
          <Pressable
            className={`border ${buttonStyle} items-center justify-center p-2 rounded-full`}
            onPress={onPressHandler}
          >
            <Font className={`${buttonTitleStyle} font-semibold`}>
              {isCurrentPlan
                ? "Current Package"
                : buttonTitle || "Select Package"}
            </Font>
          </Pressable>
        </View>
      )} */}
    </Pressable>
  );
};

export default PlanCard;
