import React from "react";
import {
  Image,
  View,
  Pressable,
  Platform,
  ImageBackground,
} from "react-native";
import { Font, A } from "../../components/font";
import colors from "../../../colors";
import { ScrollView } from "react-native";
import { AntDesign } from "@expo/vector-icons";

export default function ConfirmEmailSent({ navigation }) {
  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              // onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              ></Image>
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="h-[600px] justify-center items-center">
              <View className="rounded-full w-10 h-10 bg-app-g items-center justify-center">
                <AntDesign name="checkcircle" size={24} color={colors.app.a} />
              </View>
              <Font className="text-lg my-4 text-center">
                Registration Successful. An email has been sent with a
                Verification link to verify your email.
              </Font>
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
    </View>
  );
}
