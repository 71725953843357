import React, { useEffect, useState } from "react";
import {
  Pressable,
  View,
  TouchableOpacity,
  RefreshControl,
  Platform,
} from "react-native";
import { getStats, getRecentActivites } from "../../services/api";
import { Font } from "../../components/font";
import { Card } from "../../components/general/cards/card";
import { Footer } from "../../components/footer";
import moment from "moment";
import { ScrollView } from "react-native";
import { Button } from "../../components/input";
import { TextInput } from "react-native-gesture-handler";
import CustomTooltip from "../../components/general/tooltips/floatingToolTip";

import SVGLoader from "../../utils/SVGLoader";
import addUser from "../../../assets/add-user.svg";
import search from "../../../assets/search.svg";
import plus from "../../../assets/plus.svg";
import ManageGroups from "./manage-groups";
import { Image } from "react-native";
import { CreateAGroup, loadGroups } from "../../actions/auth-actions";
import { useAuthContext } from "../../contexts/auth-context";
import { useCallback } from "react";
import { PleaseWaitModal } from "../../components/modal";
import { createCrossPlatformFormData } from "../../utils/encDec";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { useLayoutEffect } from "react";
import RemixIcon from "react-native-remix-icon";

export default function Groups({ navigation, route }) {
  const [isGroupHandlingTab, setIsGroupHandlingTab] = useState("Create-New");
  const [isActiveTab, setIsActiveTab] = useState("Public");
  const [isReLoading, setIsReLoading] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGroupSelected, setIsGroupSelected] = useState(false);

  const closeGroup = () => {
    setIsGroupSelected(false);
  };
  const [groupId, setIsGroupId] = useState();
  const [groupName, setIsGroupName] = useState();
  const [groupDescription, setIsGroupDescription] = useState();
  const [IsPrivate, setIsPrivate] = useState();
  const [GroupStatus, setGroupStatus] = useState();

  const [isRefreshList, setRefreshList] = React.useState(true);
  const checkRefreshList = () => {
    setRefreshList(!isRefreshList);
  };
  const [isResponseModal, setIsResponseModal] = useState({
    visible: false,
    message: "",
  });
  const [groupList, setGroupList] = useState([]);
  const [group, setGroup] = useState({
    name: "",
    description: "",
  });
  const changeCredentials = (field, value) => {
    setGroup((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const [stats, setStats] = React.useState({
    total_listings: 0,
    total_products: 0,
    bookings_completed: 0,
    orders_fulfilled: 0,
  });
  const [activities, setActivities] = React.useState([]);
  const closeResponseModal = () => {
    setIsResponseModal((prev) => ({ ...prev, visible: false }));
  };

  useEffect(() => {
    getActivities();
  }, []);

  function groupHandler() {
    setIsVisible(!isVisible);
    setIsGroupSelected(false);
  }

  const getActivities = async () => {
    try {
      let res = await getStats();
      if (res.status === 200) setStats(res.data.data);
      else
        setStats({
          total_listings: 0,
          total_products: 0,
          bookings_completed: 0,
          orders_fulfilled: 0,
        });
      let activities = await getRecentActivites();
      if (activities.status === 200) setActivities(activities.data.data || []);
    } catch (error) {
      console.log("Error Getting Stats : ", error.response);
    }
  };

  const { user } = useAuthContext();

  const CreateANewGroup = async () => {
    if (Object.keys(group)?.length) {
      for (const key in group) {
        if (!group[key]) {
          delete group[key];
        }
      }
    }
    // const formData = createCrossPlatformFormData({
    //   data: group,
    // });
    const formData = new FormData();
    formData.append("name", group.name);
    formData.append("description", group.description);
    formData.append("is_private", isActiveTab == "Public" ? false : true);
    try {
      console.log(group, "group");

      setIsLoading(true);
      const res = await CreateAGroup(
        user?.id,
        group.name,
        group.description,
        isActiveTab == "Public" ? false : true,
        formData
      );

      if (res?.status == 200) {
        setIsLoading(false);
        // setText("Requested");
        // console.log("res status for new group", res.status);
        setGroup(() => ({
          name: "",
          description: "",
        }));
        setIsResponseModal((prev) => ({
          ...prev,
          visible: true,
          message: "Group Created Successfully !!!",
        }));
        setGroupList((prev) => [...prev, res.data.data]);
      }
      if (res?.status == 400) {
        setIsLoading(false);
        // setText("Requested");
        console.log("res status for new group", res);
        setGroup(() => ({
          name: "",
          description: "",
        }));
        setIsResponseModal((prev) => ({
          ...prev,
          visible: true,
          message: "Group limit has been reached !!!",
        }));
      }
    } catch (error) {
      setGroup(() => ({
        name: "",
        description: "",
      }));
      setIsLoading(false);
      console.log("error?.code:", error?.code, error);
    } finally {
      setIsLoading(false);
      // setGettingUserInfo(false);
    }
  };
  const LoadGroups = async () => {
    try {
      // setIsLoading(true);
      const res = await loadGroups(user?.id);
      if (res?.status) {
        setGroupList(res.data.data);
        // setIsLoading(false);
        // setText("Requested");
        // console.log("res for loading group", res.data);
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error?.code for get groups :", error?.code, error);
    } finally {
      // setIsLoading(false);
      // setGettingUserInfo(false);
    }
  };
  useLayoutEffect(() => {
    LoadGroups();
  }, [isRefreshList]);
  const timeAgo = (updatedAt) => moment(updatedAt).fromNow();
  return (
    <View className="flex-1 bg-app-e lg:bg-gray-50">
      {isLoading && <PleaseWaitModal showLoader={true} />}
      <ResponseModal
        visible={isResponseModal.visible}
        onRequestClose={closeResponseModal}
        bodyText={isResponseModal.message}
      ></ResponseModal>

      <ScrollView
        className="p-4 rounded-t-2xl bg-gray-50"
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        refreshControl={
          Platform.OS === "web" ? null : isGroupHandlingTab === "Manage" ? (
            <RefreshControl
              refreshing={isReLoading}
              onRefresh={() => {
                setIsReLoading(true);
                LoadGroups();
                setTimeout(() => setIsReLoading(false), 1000);
              }}
            />
          ) : null
        }
      >
        <View className="  w-full p-2 h-full">
          <View className="my-5 hidden lg:flex px-4">
            <Font className="text-lg font-semibold text-app-d">Groups</Font>
          </View>
          <View className="p-4 flex-col lg:flex-row justify-start items-center">
            {/* ----------------------Lg View Create Group---------------------------- */}

            <View className="hidden lg:flex flex-col lg:h-full ">
              <Card className="mb-5 ">
                <View className="p-4 mb-0 flex">
                  <View className=" px-3 py-2 w-full flex-row justify-center items-center bg-blue-100 rounded-lg">
                    <View className="w-4 h-4 mr-2">
                      <SVGLoader
                        src={plus}
                        height={40}
                        width={40}
                        webHeight={"20em"}
                        webWidth={"auto"}
                      />
                    </View>
                    <Pressable
                      onPress={() => {
                        groupHandler();
                      }}
                    >
                      <Font className="text-sm text-blue-500 self center first-letter">
                        Create new group
                      </Font>
                    </Pressable>
                  </View>
                </View>
              </Card>

              {/* --------------------------Groups----------------------------------------  */}

              <Card className="w-full   ">
                <View className="w-full p-4 flex-col">
                  <View className="w-full mb-3 pb-2 flex-row justify-between item-center border-b-2 border-b-gray-200">
                    <Font className="text-sm font font-bold self-center">
                      Your groups
                    </Font>
                    <View className="flex-row">
                      {/* <Font className="text-sm text-app-e self-center">
                        See all
                      </Font> */}
                    </View>
                  </View>
                  {/* <View className="w-full flex justify-center items-center">
                    <View className=" w-full mb-6 flex-row bg-neutral-300 rounded-full shadow-sm">
                      <View className="px-2 py-1 w-full flex-row justify-between items-center">
                        <TextInput
                          className="w-full lg:w-1/2 ml-2"
                          placeholder="...Search"
                          placeholderTextColor="#94a3b8"
                          type="text"
                        />
                        <View className="w-3 h-3 mr-2 flex justify-center items-center">
                          <SVGLoader
                            src={search}
                            height={40}
                            width={40}
                            webHeight={"20em"}
                            webWidth={"auto"}
                          />
                        </View>
                      </View>
                    </View>
                  </View> */}

                  <View className="w-full flex justify-center items-center">
                    {groupList.length == 0 ? (
                      <>
                        <Font className="text-app-d1 text-xs" weight={500}>
                          No Data
                        </Font>
                      </>
                    ) : (
                      groupList
                        ?.slice()
                        .reverse()
                        .map((e, i) => (
                          <Pressable
                            key={i}
                            className={`p-2 px-3 w-full mb-4 flex-row justify-center items-center shadow-sm rounded-lg ${
                              groupId == e?.id ? "bg-app-e" : "bg-gray-200"
                            }`}
                            onPress={() => {
                              setIsGroupId(e?.id);
                              setIsGroupName(e?.name);
                              setIsGroupDescription(e?.description);
                              setIsPrivate(e?.is_private);
                              setGroupStatus(e?.limit_group_posting_for_role);
                              setIsGroupSelected(true);
                            }}
                          >
                            <View className="flex-col">
                              <Font
                                className={`text-sm font-bold lg:flex lg:flex-row ${
                                  groupId == e?.id ? "text-app-a" : "text-black"
                                }`}
                              >
                                {e?.name}
                                <View className="items-center flex-row flex">
                                  <View>
                                    <RemixIcon
                                      name="ri-checkbox-blank-circle-fill"
                                      size={4}
                                      color={
                                        groupId == e?.id ? "white" : "#000000"
                                      }
                                      className=" ml-2 mr-1  lg:mt-0  flex-shrink-0 justify-center items-center hidden lg:flex"
                                    />
                                  </View>
                                  <View>
                                    <RemixIcon
                                      className="hidden lg:flex"
                                      name={
                                        e?.is_private
                                          ? `ri-lock-fill`
                                          : `ri-earth-fill`
                                      }
                                      size={14}
                                      color={
                                        groupId == e?.id ? "white" : "#000000"
                                      }
                                    />
                                  </View>
                                </View>
                              </Font>
                              <Font
                                className={`text-xs ${
                                  groupId == e?.id
                                    ? "text-app-a"
                                    : "text-gray-400"
                                }`}
                              >
                                updated {timeAgo(e?.updated_at)}
                              </Font>
                            </View>
                          </Pressable>
                        ))
                    )}
                  </View>
                </View>
              </Card>
            </View>
            <View className="hidden lg:flex p-3 " />

            {/* ---------------------Create/Manage-Tabs----------------------------------- */}

            {isGroupSelected === false && (
              <View className="w-full flex-col flex-1  ">
                <View className="block lg:hidden mb-6 flex-row lg:flex-row w-full justify-center lg:justify-start">
                  <Pressable
                    className={`ml-1 min-w-max shadow-sm  border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg px-2 py-2 ${
                      isGroupHandlingTab === "Create-New"
                        ? "bg-app-e"
                        : "bg-none"
                    }`}
                    onPress={() => {
                      setIsGroupHandlingTab("Create-New");
                    }}
                  >
                    <Font
                      className={`text font-medium self-center justify-self-center ${
                        isGroupHandlingTab === "Create-New"
                          ? "text-app-a"
                          : "text-app-d1"
                      }`}
                      weight={600}
                    >
                      Create new
                    </Font>
                  </Pressable>
                  <Pressable
                    className={`flex lg:hidden ml-1 min-w-max shadow-sm  border border-gray-200  rounded-tl-lg rounded-tr-lg rounded-bl-lg rounded-br-lg px-2 py-2 ${
                      isGroupHandlingTab === "Manage" ? "bg-app-e" : "bg-none"
                    }`}
                    onPress={() => {
                      setIsGroupHandlingTab("Manage");
                    }}
                  >
                    <Font
                      className={`text font-medium self-center justify-self-center ${
                        isGroupHandlingTab === "Manage"
                          ? "text-app-a "
                          : "text-app-d1"
                      }`}
                      weight={600}
                    >
                      Manage
                    </Font>
                  </Pressable>
                </View>
                <View className="flex-col lg:flex-row  w-full">
                  {/* -------------------Create-Group sm/lg-Tab----------------------------------------- */}

                  {isGroupHandlingTab === "Create-New" && (
                    <View className="w-full p-0 pt-0 ">
                      <Card className="flex-col lg:flex-row mb-5">
                        <View className="flex-col lg:flex-row w-full m-4 mb-0 mt-0 p-4">
                          <View className="flex-col w-full lg:w-1/2">
                            <View className="my-3 hidden lg:flex lg:pl-3">
                              <Font className="text-sm font-semibold text-app-d mb-2">
                                Groups
                              </Font>
                              <View className="border-t-2 border-t-gray-200 w-full" />
                            </View>
                            <View className="w-full lg:pl-3">
                              <TextInput
                                className="w-full lg:w-11/12 border-b border-b-gray-200 lg:border lg:border-gray-200 lg:bg-gray-50 p-2 mb-4 lg:mb-0"
                                placeholder="Group name"
                                placeholderTextColor="#94a3b8"
                                type="text"
                                value={group.name}
                                style={{ outline: "none" }}
                                onChangeText={(value) =>
                                  changeCredentials("name", value)
                                }
                              />
                            </View>
                          </View>
                          <View className="flex-col w-full lg:w-1/2">
                            <View className="my-3 hidden lg:flex lg:pr-3">
                              <View className="flex-row  lg:mb-2">
                                <Font className="text-sm font-semibold text-app-d ">
                                  Description
                                </Font>
                                <Font className="text-sm text-gray-200 ml-2">
                                  (Optional)
                                </Font>
                              </View>
                              <View className="border-t-2 border-t-gray-200 w-full" />
                            </View>
                            <View className="w-full lg:pr-3">
                              <TextInput
                                className="w-full lg:w-11/12 border-b border-b-gray-200 lg:border lg:border-gray-200 lg:bg-gray-50 p-2 mb-4 lg:mb-0"
                                placeholder="Group Description"
                                placeholderTextColor="#94a3b8"
                                type="text"
                                value={group.description}
                                style={{ outline: "none" }}
                                onChangeText={(value) =>
                                  changeCredentials("description", value)
                                }
                              />
                            </View>
                          </View>
                        </View>
                      </Card>

                      <Card className="hidden lg:flex w-full">
                        <View className="my-3 p-6 pb-0 w-full">
                          <Font className="text-sm font-semibold text-app-d mb-2">
                            Group accessibility
                          </Font>
                          <View className="border-t-2 border-t-gray-200 w-full" />
                        </View>
                        <View className="flex-row justify-center mt-0 mb-20">
                          <CustomTooltip
                            align="bottom-right"
                            tooltipText="Viewed and access by all register users."
                            onPress={() => {
                              setIsActiveTab("Public");
                            }}
                          >
                            <View
                              className={`min-w-max shadow-sm  border rounded-tl-lg rounded-bl-lg px-8 py-2 pb-4 ${
                                isActiveTab === "Public"
                                  ? "bg-red-200 border-app-e"
                                  : "border-gray-300 "
                              } `}
                            >
                              <Font
                                className={`text font-medium self-center justify-self-center ${
                                  isActiveTab === "Public"
                                    ? "text-app-e"
                                    : "text-gray-300"
                                }`}
                                weight={600}
                              >
                                Public
                              </Font>
                            </View>
                          </CustomTooltip>
                          <View className="p-2" />
                          <CustomTooltip
                            align="bottom-left"
                            tooltipText="Viewed and access only by group members."
                            onPress={() => {
                              setIsActiveTab("Private");
                            }}
                          >
                            <View
                              className={`min-w-max shadow-sm  rounded-tr-lg rounded-br-lg px-8 py-2  pb-4 border ${
                                isActiveTab === "Private"
                                  ? "border-app-e bg-red-200"
                                  : "border-gray-300"
                              }`}
                            >
                              <Font
                                className={`text font-medium self-center justify-self-center ${
                                  isActiveTab === "Private"
                                    ? "text-app-e"
                                    : "text-gray-300"
                                }`}
                                weight={600}
                              >
                                Private
                              </Font>
                            </View>
                          </CustomTooltip>
                        </View>
                      </Card>

                      <View className="block lg:hidden flex-row justify-center mt-10 mb-20">
                        <CustomTooltip
                          align="bottom-right"
                          tooltipText="Viewed and access by all register users."
                          onPress={() => {
                            setIsActiveTab("Public");
                          }}
                        >
                          <View
                            className={`min-w-max shadow-sm  border rounded-tl-lg rounded-bl-lg px-8 py-2 ${
                              isActiveTab === "Public"
                                ? "bg-red-200 border-app-e"
                                : "border-gray-300 "
                            } `}
                          >
                            <Font
                              className={`text font-medium self-center justify-self-center ${
                                isActiveTab === "Public"
                                  ? "text-app-e"
                                  : "text-gray-300"
                              }`}
                              weight={600}
                            >
                              Public
                            </Font>
                          </View>
                        </CustomTooltip>
                        <CustomTooltip
                          align="bottom-left"
                          tooltipText="Viewed and access only by group members."
                          onPress={() => {
                            setIsActiveTab("Private");
                          }}
                        >
                          <View
                            className={`min-w-max shadow-sm  rounded-tr-lg rounded-br-lg px-8 py-2  border  ${
                              isActiveTab === "Private"
                                ? "border-app-e bg-red-200"
                                : "border-gray-300"
                            }`}
                          >
                            <Font
                              className={`text font-medium self-center justify-self-center ${
                                isActiveTab === "Private"
                                  ? "text-app-e"
                                  : "text-gray-300"
                              }`}
                              weight={600}
                            >
                              Private
                            </Font>
                          </View>
                        </CustomTooltip>
                      </View>
                      {/* <Card className="mb-10">
                        <TouchableOpacity
                          className="px-4 py-2 flex-row justify-between w-full"
                          onPress={() =>
                            navigation.navigate("add-member", {
                              checkRefreshListforMembers: checkRefreshList,
                            })
                          }
                        >
                          <View className="flex-row justify-start items-center">
                            {isActiveTab === "Public" && (
                              <Pressable className="w-8 h-8">
                                <SVGLoader
                                  src={addUser}
                                  height={30}
                                  width={30}
                                  webHeight={"20em"}
                                  webWidth={"auto"}
                                />
                              </Pressable>
                            )}
                            <Font className="lg:text-lg text-base font-medium">
                              Add / Invite
                            </Font>
                          </View>
                          <View className="flex flex-row justify-center items-center">
                            <Pressable
                              className="w-8 h-8"
                              // onPress={() => navigation.navigate("add-member")}
                            >
                              <Image
                                source={require("../../../assets/arrow-red-fill.png")}
                                className="w-full h-full"
                              ></Image>
                            </Pressable>
                          </View>
                        </TouchableOpacity>
                      </Card> */}
                      <View className="mb-12 flex-row lg:flex-row w-full justify-center lg:justify-end">
                        <View className="hidden lg:flex">
                          <Button
                            label="Cancel"
                            type="regular-outline"
                            className="min-w-max px-6 mx-1"
                            // style={{ flex: 1 }}
                            // onPress={() => navigation.navigate("")}
                          />
                        </View>
                        <Button
                          label="Create"
                          type="primary"
                          className="min-w-max px-6 mx-1"
                          disabled={group.name == "" ? true : false}
                          // style={{ flex: 1 }}
                          onPress={() =>
                            group.name == "" ? null : CreateANewGroup()
                          }
                        />
                      </View>
                    </View>
                  )}

                  {/* -------------------Manage-Group sm-Tab----------------------------------------- */}

                  {isGroupHandlingTab === "Manage" && (
                    <View className="flex lg:hidden justify-center items-center">
                      {/* <View className="mt-10 mb-4 px-4 w-full flex-row bg-white border border-gray-200 rounded-full shadow-sm">
                        <View className="flex-row justify-between items-center w-full">
                          <TextInput
                            className="w-full pr-5"
                            placeholder="...Search"
                            placeholderTextColor="#94a3b8"
                            type="text"
                          />
                          <View className="-ml-2 flex-row justify-center items-center">
                            <SVGLoader
                              src={search}
                              height={20}
                              width={20}
                              webHeight={"2em"}
                              webWidth={"auto"}
                            />
                          </View>
                        </View>
                      </View> */}
                      {groupList.length == 0 ? (
                        <>
                          <Font className="text-app-d1 text-xs" weight={500}>
                            No Data
                          </Font>
                        </>
                      ) : (
                        groupList
                          ?.slice()
                          .reverse()
                          .map((e, i) => (
                            <Card className="mb-0 mx-2 p-2 w-full" key={i}>
                              <Pressable
                                className="p-2 flex-col justify-start w-full"
                                onPress={() => {
                                  setIsSelected(!isSelected);

                                  navigation.navigate("manage-groups", {
                                    id: e?.id,
                                    name: e?.name,
                                    description: e?.description,
                                    isPrivate: e?.is_private,
                                    groupStatus:
                                      e?.limit_group_posting_for_role,
                                    checkRefreshList: checkRefreshList,
                                  });
                                }}
                              >
                                <View className="flex-col">
                                  <Font className="text-base font-bold flex flex-row">
                                    {e?.name}
                                    <View className="items-center flex-row flex">
                                      <View>
                                        <RemixIcon
                                          name="ri-checkbox-blank-circle-fill"
                                          size={4}
                                          color="#000000"
                                          className=" ml-2 mr-1 flex"
                                        />
                                      </View>
                                      <View>
                                        <RemixIcon
                                          className="flex"
                                          name={
                                            e?.is_private
                                              ? `ri-lock-fill`
                                              : `ri-earth-fill`
                                          }
                                          size={14} // or use Tailwind text-size class
                                          color="#000000"
                                        />
                                      </View>
                                    </View>
                                  </Font>
                                  <Font className="text-sm text-gray-300 mb-0">
                                    updated {timeAgo(e?.updated_at)}
                                  </Font>
                                </View>
                              </Pressable>
                            </Card>
                          ))
                      )}
                    </View>
                  )}
                </View>
              </View>
            )}
            {isGroupSelected === true ? (
              <View className="w-[82%]  block lg:flex-row lg:items-center lg:justify-center ">
                <ManageGroups
                  key={groupId}
                  closeGroup={closeGroup}
                  navigation={navigation}
                  Id={groupId}
                  Name={groupName}
                  Description={groupDescription}
                  IsPrivate={IsPrivate}
                  GroupStatus={GroupStatus}
                  checkRefreshList={checkRefreshList}
                />
              </View>
            ) : (
              <></>
            )}
          </View>
          <Footer navigation={navigation} />
        </View>
      </ScrollView>
    </View>
  );
}
