import { View, Pressable, Image, Platform, Modal, Share } from "react-native";
import React from "react";
import colors from "../../../colors";
import RemixIcon from "react-native-remix-icon";
import { Font } from "../../components/font";
import { FlatList } from "react-native";
import { Card } from "../../components/general/cards/card";
import ResponseModal from "../../components/general/modals/ResponseModal";
import { useAuthContext } from "../../contexts/auth-context";
import { useVendorFeedContext } from "../../contexts/vendor-feed-context";
import { ActivityIndicator } from "react-native";
import { isSignedIn, ShareAPost, ShareAPostOfWall } from "../../actions/auth-actions";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2, appURI, mediaURI } from "../../constants";
import { ScrollView } from "react-native";
import SVGLoader from "../../utils/SVGLoader";
import { useSharedContext } from "../../contexts/shared-context";
import { Button, Input, Select } from "../../components/input";
import ConfirmModal from "../../components/general/modals/ConfirmModal";
import MediaUpload from "../../components/general/uploads/media-upload";
import useInputHandler from "../../utils/useInputHandler";
import { cloneDeep } from "lodash";
import { Unauthorized } from "./unauthorized";
import { getFullDateTime } from "../../utils/date-time-util";
import { RefreshControl } from "react-native";
import {
  createCrossPlatformFormData,
  fileURLtoBase64NBlob,
} from "../../utils/encDec";
import { DefaultImages } from "../../constants/predefined-constants";
import { catchAPIErrorText } from "../../utils/error-handler";

const initial_from = 1,
  load_records = 500;

const NewPostModal = ({
  setPostsData,
  setShowResponseModal,
  visible,
  onRequestClose,
  user_id,
  data: { isEditMode, index, post } = {},
}) => {
  const {
    id,
    title,
    description,
    type,
    // contact_number,
    // contact_email,
    // address,
    cover_image_url,
  } = post || {};
  const [isPostAdding, setIsPostAdding] = React.useState();
  const [isPostUpdating, setIsPostUpdating] = React.useState();
  const [pickedImage, setPickedImage] = React.useState();
  const {
    inputValue: titleInputValue,
    currentValue: titleValue,
    onValueChangeHandler: onChangeTitle,
    onBlurHandler: onBlurTitle,
    hasError: titleHasError,
    reset: resetTitle,
  } = useInputHandler({
    defaultValue: title,
  });
  const {
    inputValue: descriptionInputValue,
    currentValue: descriptionValue,
    onValueChangeHandler: onChangeDescription,
    onBlurHandler: onBlurDescription,
    hasError: descriptionHasError,
    reset: resetDescription,
  } = useInputHandler({
    defaultValue: description,
  });
  const {
    inputValue: typeInputValue,
    currentValue: typeValue,
    onValueChangeHandler: onChangeType,
    onBlurHandler: onBlurType,
    hasError: typeHasError,
    reset: resetType,
  } = useInputHandler({
    defaultValue: type,
  });
  // const {
  //   inputValue: contactNumberInputValue,
  //   currentValue: contactNumberValue,
  //   onValueChangeHandler: onChangeContactNumber,
  //   onBlurHandler: onBlurContactNumber,
  //   hasError: contactNumberHasError,
  //   reset: resetContactNumber,
  // } = useInputHandler({
  //   defaultValue: contact_number,
  // });
  // const {
  //   inputValue: contactEmailInputValue,
  //   currentValue: contactEmailValue,
  //   onValueChangeHandler: onChangeContactEmail,
  //   onBlurHandler: onBlurContactEmail,
  //   hasError: contactEmailHasError,
  //   reset: resetContactEmail,
  // } = useInputHandler({
  //   defaultValue: contact_email,
  // });
  // const {
  //   inputValue: addressInputValue,
  //   currentValue: addressValue,
  //   onValueChangeHandler: onChangeAddress,
  //   onBlurHandler: onBlurAddress,
  //   hasError: addressHasError,
  //   reset: resetAddress,
  // } = useInputHandler({
  //   defaultValue: address,
  // });

  const handleError = (e) => {
    setShowResponseModal({
      visible: true,
      onRequestClose: () => {
        setShowResponseModal({
          visible: false,
          onRequestClose: () => null,
          bodyText: "",
        });
      },
      bodyText: catchAPIErrorText(
        e,
        "Unknown Error while Getting Vendor Posts"
      ),
    });
  };

  const onSubmitHandler = () => {
    if (isEditMode) {
      let updatedData = {};
      if (titleInputValue)
        updatedData.title = titleInputValue != undefined ? titleInputValue : "";
      if (descriptionInputValue)
        updatedData.description =
          descriptionInputValue != undefined ? descriptionInputValue : "";
      if (typeInputValue)
        updatedData.type = typeInputValue != undefined ? typeInputValue : "";
      // if (contactNumberInputValue)
      //   updatedData.contact_number =
      //     contactNumberInputValue != undefined ? contactNumberInputValue : "";
      // if (contactEmailInputValue)
      //   updatedData.contact_email =
      //     contactEmailInputValue != undefined ? contactEmailInputValue : "";
      // if (addressInputValue)
      //   updatedData.address =
      //     addressInputValue != undefined ? addressInputValue : "";
      if (pickedImage) updatedData.cover_image = pickedImage;

      const updatePost = async (updatedData) => {
        if (!Object.keys(updatedData)?.length) {
          return;
        }
        setIsPostUpdating(true);
        const formData = createCrossPlatformFormData({
          blobDataKeys: "cover_image",
          data: updatedData,
        });
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.patch(
            apiURIV2 + "/users/" + user_id + "/posts/" + id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (res.status === 200) {
            setPostsData((data) => {
              data[index] = res.data.post;
              return cloneDeep(data);
            });
          }
        } catch (e) {
          console.log("e.res", e?.response, e, JSON.stringify(e, null, 1));
          handleError(e);
        } finally {
          setIsPostUpdating(false);
          onRequestClose();
        }
      };
      updatePost(updatedData);
    } else {
      const postData = {
        title: titleInputValue != undefined ? titleInputValue : "",
        description:
          descriptionInputValue != undefined ? descriptionInputValue : "",
        type: typeInputValue != undefined ? typeInputValue : "",
        // contact_number:
        //   contactNumberInputValue != undefined ? contactNumberInputValue : "",
        // contact_email:
        //   contactEmailInputValue != undefined ? contactEmailInputValue : "",
        // address: addressInputValue != undefined ? addressInputValue : "",
        cover_image: pickedImage != undefined ? pickedImage : "",
      };
      const addPost = async (postData) => {
        if (!Object.keys(postData)?.length) {
          return;
        }
        setIsPostAdding(true);
        const formData = createCrossPlatformFormData({
          blobDataKeys: "cover_image",
          data: postData,
        });
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.post(
            apiURIV2 + "/users/" + user_id + "/posts",
            formData,
            {
              headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (res.status === 201) {
            setPostsData((data) => {
              data.unshift(res.data.post);
              return cloneDeep(data);
            });
          }
        } catch (e) {
          console.log(
            "e.res for creating a post ",
            e?.response,
            e,
            JSON.stringify(e, null, 4)
          );
          handleError(e);
        } finally {
          onRequestClose();
          setIsPostAdding(false);
        }
      };
      addPost(postData);
    }
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <View className="flex-1 justify-center items-center bg-black/50 px-8">
        <Card
          title="Post Details"
          Widget={() => (
            <Pressable onPress={onRequestClose}>
              <RemixIcon
                name="ri-close-fill"
                className=""
                size={25}
                color={colors.app.e}
              />
            </Pressable>
          )}
          className="w-full mx-4 md:w-2/3 lg:w-1/3"
        >
          <View className="w-full justify-center h-[416px]">
            <ScrollView
              className="pr-8 pl-10"
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              keyboardShouldPersistTaps="always"
            >
              <View className="flex-col flex-wrap w-full">
                <View className="w-full pb-2">
                  <Input
                    type="text"
                    label="Title"
                    value={titleValue}
                    onChangeText={onChangeTitle}
                    onBlur={onBlurTitle}
                    note={titleHasError}
                  />
                </View>
                <View className="w-full pb-2">
                  <Input
                    type="longtext"
                    label="Description"
                    value={descriptionValue}
                    onChangeText={onChangeDescription}
                    onBlur={onBlurDescription}
                    note={descriptionHasError}
                  />
                </View>
                <View className="w-full pb-2">
                  <Select
                    label="Type"
                    options={[
                      "achievement",
                      "activity",
                      "announcement",
                      "feature",
                    ]}
                    placeholder="Select Post Type"
                    value={typeValue}
                    setValue={onChangeType}
                    note={typeHasError}
                  />
                </View>
                {/* <View className="w-full pb-2">
                  <Input
                    type="number"
                    label="Contact Number"
                    value={contactNumberValue}
                    onChangeText={onChangeContactNumber}
                    onBlur={onBlurContactNumber}
                    note={contactNumberHasError}
                  />
                </View> */}
                {/* <View className="w-full pb-2">
                  <Input
                    type="text"
                    label="Contact Email"
                    value={contactEmailValue}
                    onChangeText={onChangeContactEmail}
                    onBlur={onBlurContactEmail}
                    note={contactEmailHasError}
                  />
                </View> */}
                {/* <View className="w-full pb-2">
                  <Input
                    type="text"
                    label="Address"
                    value={addressValue}
                    onChangeText={onChangeAddress}
                    onBlur={onBlurAddress}
                    note={addressHasError}
                  />
                </View> */}
                <View className="pb-2 w-full flex-1">
                  <Font className="flex-1 text-app-d1 py-2" weight={600}>
                    Cover Image
                  </Font>
                  <View className="w-full ">
                    <MediaUpload
                      pickedImage={pickedImage}
                      onImagePick={(data) => setPickedImage(data)}
                      placeholderImage={
                        cover_image_url
                          ? { uri: mediaURI + cover_image_url }
                          : null
                      }
                    />
                  </View>
                </View>
              </View>
            </ScrollView>
            <View className="px-10 pb-4 pt-4">
              <Button
                waitState={isPostUpdating || isPostAdding}
                onPress={() => {
                  typeValue == "" || titleValue == ""
                    ? null
                    : onSubmitHandler();
                }}
                type={
                  typeValue == "" || titleValue == "" ? "disabled" : "primary"
                }
                label={isEditMode ? "Update Post" : "Create Post"}
              />
            </View>
          </View>
        </Card>
      </View>
    </Modal>
  );
};

const VendorFeed = ({ navigation, setTabStatus, tabStatus }) => {
  const { user } = useAuthContext();
  const { screen: { width } = {} } = useSharedContext();
  const { posts, setPosts, setPostsPagination, postsPagination } =
    useVendorFeedContext();
  const [showResponseModal, setShowResponseModal] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [showConfirmModal, setShowConfirmModal] = React.useState({
    visible: false,
    bodyText: "",
    BodyComponent: "",
    cancelText: "Cancel",
    confirmText: "Confirm",
    onCancel: () => null,
    onConfirm: () => null,
  });
  const [showCreatePostModal, setShowCreatePostModal] = React.useState({
    visible: false,
    onRequestClose: () =>
      setShowCreatePostModal((d) => {
        d.visible = false;
        d.data.post = null;
        d.data.index = null;
        d.data.isEditMode = false;
        d.user_id = null;
        return { ...d };
      }),
    data: {
      isEditMode: true,
      index: null,
      post: null,
    },
    user_id: user?.id,
  });
  const [vPIsLoading, setVPIsLoading] = React.useState();
  const [postsData, setPostsData] = React.useState();
  const [isPostsFailed, setIsPostsFailed] = React.useState();
  const [showMoreDesc, setShowMoreDesc] = React.useState();

  // React.useEffect(() => {
  //   setTabStatus({ curr: "My Feed", prev: tabStatus.curr });
  // }, []);

  // React.useEffect(() => {
  //   return () => {
  //     if (!postsData?.length) setPostsData();
  //   };
  // }, [postsData]);

  const getVendorPosts = async (offset, limit, push) => {
    setVPIsLoading(true);
    let data, total_count;
    try {
      const token = await isSignedIn();
      if (!token || !user) return;
      const res = await axiosInstance.get(
        apiURIV2 + "/users/" + user.id + "/posts",
        {
          params: { offset, limit: limit || load_records },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        // total_count = res.data.total_count;
        data = res.data.posts;
      }
    } catch (e) {
      console.log("vendors e.res", e?.response, e);

      setShowResponseModal({
        visible: true,
        onRequestClose: () => {
          setShowResponseModal({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: catchAPIErrorText(
          e,
          "Unknown Error while Getting Vendor Posts"
        ),
      });
      setIsPostsFailed(true);
    } finally {
      // const pagObj = {
      //   from: (offset || -1) + 1 || initial_from,
      //   to:
      //     (offset || -1) + 1
      //       ? offset + (data?.length || load_records)
      //       : data?.length || load_records,
      //   total: total_count,
      // };
      // setPostsPagination({ data: pagObj });
      setPosts({ data: data || [] });
      setPostsData(data || []);
      setVPIsLoading(false);
    }
  };

  if (!postsData && !posts && !vPIsLoading) {
    getVendorPosts();
  } else if (!postsData && posts) {
    setPostsData(posts.slice(0, load_records));
  }

  const sharePostApi = async (data, postId, index) => {
    try {
      const result = await (Platform.OS === "web"
        ? window.navigator.share({
            text: data.message,
            url: data.url,
            title: data.title,
          })
        : Share.share({
            message: data.message,
            url: data.url,
            title: data.title,
          }));

      if (result?.action === Share.sharedAction || Platform.OS === "web") {
        if (result?.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
        try {
          let res = await ShareAPostOfWall(user?.id, postId, "");
          if (res.status === 201) {
            setPostsData((data) => {
              data[index].shares++;
              return cloneDeep(data);
            });
            // setGroupPost(res.data.posts);
            // console.log(res.data, "response check success for share posts");
          } else {
            console.log(res.data, "response check failed for share posts");
          }
        } catch (error) {
          console.log("Error Getting share post : ", error);
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
        console.log("Share action was dismissed.");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  if (!user) {
    return <Unauthorized />;
  } else if (isPostsFailed) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <View className="p-4">
          <Font className="text-app-d font-semibold text-base" weight={700}>
            Failed to Load the Data
          </Font>
          <Font className="text-app-d1 py-2">
            Unable to Get Data from the Server
          </Font>
          <Button
            label="Retry"
            type="primary"
            onPress={() => {
              if (!vPIsLoading) {
                getVendorPosts();
                setIsPostsFailed(false);
              }
            }}
          />
        </View>
      </View>
    );
  } else if (!posts || !postsData) {
    return (
      <View className="flex-1 justify-center items-center">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <ActivityIndicator
          animating={!posts && !postsData}
          size={Platform.OS === "web" ? "small" : "large"}
          color={colors.app.e}
          className="mb-4 shadow-md rounded-full p-2"
        />
      </View>
    );
  }

  const onShare = async (data, pid, index) => {
    try {
      const result = await (Platform.OS === "web"
        ? window.navigator.share({
            text: data.message,
            url: data.url,
            title: data.title,
          })
        : Share.share({
            message: data.message,
            url: data.url,
            title: data.title,
          }));
      if (result?.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
        try {
          const token = await isSignedIn();
          const res = await axiosInstance.post(
            apiURIV2 + "/users/" + user.id + "/posts/" + pid + "/share",
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.status === 201) {
            posts[index].shares++;
            setPosts({ data: posts });
          }
        } catch (e) {
          console.log("Vendor Feed e.res", e?.response, e);
          setShowResponseModal({
            visible: true,
            onRequestClose: () => {
              setShowResponseModal({
                visible: false,
                onRequestClose: () => null,
                bodyText: "",
              });
            },
            bodyText: catchAPIErrorText(
              e,
              "Unknown Error while Sharing Vendor Feed Post"
            ),
          });
        }
      } else if (result?.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <View className="flex flex-row items-center bg-gray-50 justify-start h-full">
      {/* <View className="w-full h-full flex-row"> */}
      <View className="w-full h-full flex flex-col">
        {showResponseModal.visible && (
          <ResponseModal
            visible={showResponseModal.visible}
            onRequestClose={showResponseModal.onRequestClose}
            bodyText={showResponseModal.bodyText}
          />
        )}
        <FlatList
          className="w-full lg:pb-0 px-4 self-center justify-self-center"
          contentContainerStyle={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexGrow: 1,
          }}
          refreshControl={
            <RefreshControl
              refreshing={vPIsLoading}
              onRefresh={() => getVendorPosts()}
            />
          }
          keyboardShouldPersistTaps="always"
          data={postsData}
          // key={width}
          // keyExtractor={(item, index) =>
          //   `vendor_posts_data_${item.id}_${index}`
          // }
          ListEmptyComponent={() => {
            if (isPostsFailed) {
              return (
                <View className="flex-1 justify-center items-center">
                  <View className="p-4">
                    <Font
                      className="text-app-d font-semibold text-base"
                      weight={700}
                    >
                      Failed to Load the Data
                    </Font>
                    <Font className="text-app-d1 py-2">
                      Unable to Connect the Server
                    </Font>
                    <Button
                      label="Retry"
                      type="primary"
                      onPress={() => {
                        if (!vPIsLoading) {
                          getVendorPosts();
                          setIsPostsFailed(false);
                        }
                      }}
                    />
                  </View>
                </View>
              );
            } else if (!postsData) {
              return (
                <View className="flex-1 justify-center items-center">
                  <ActivityIndicator
                    animating={!posts && !postsData}
                    size={Platform.OS === "web" ? "small" : "large"}
                    color={colors.app.e}
                    className="mb-4 shadow-md rounded-full p-2"
                  />
                </View>
              );
            } else {
              return (
                <View className="h-full flex-1 justify-center items-center">
                  <Font>No Posts Found</Font>
                </View>
              );
            }
          }}
          // showsVerticalScrollIndicator={false}
          // initialNumToRender={10}
          // numColumns={width <= 768 ? 1 : width > 1280 ? 3 : 2}
          renderItem={({ item: post, index: index1 }) => {
            return (
              <Card className="w-full self-center lg:w-4/6 xl:w-3/6">
                <View className="w-full h-max block p-4">
                  <View className="flex-row gap-4">
                    <View className="rounded-full w-12 h-12 overflow-hidden">
                      <Image
                        source={
                          user?.profile_image_url
                            ? { uri: mediaURI + user.profile_image_url }
                            : DefaultImages.DP_PLACEHOLDER
                        }
                        className="w-full h-full"
                      ></Image>
                    </View>
                    <View className="flex justify-center flex-1">
                      <Font className="font-bold text-[16px] pb-1 text-app-d">
                        {post?.title || "Untitled Post"}
                      </Font>
                      <View className="flex-row items-center">
                        <Font className="text-[12px] text-app-d1 capitalize">
                          {post?.type}
                        </Font>
                        <Font className="px-1 text-xs text-app-d1 -mb-px">
                          •
                        </Font>
                        <Font className="text-[12px] text-app-d1">
                          {getFullDateTime(post.created_at)}
                        </Font>
                      </View>
                    </View>
                    <View className="flex-row self-start">
                      <Pressable
                        className="mr-2"
                        onPress={() => {
                          setShowCreatePostModal((d) => {
                            return {
                              visible: true,
                              onRequestClose: () =>
                                setShowCreatePostModal((d) => {
                                  d.visible = false;
                                  d.data.post = null;
                                  d.data.index = null;
                                  d.data.isEditMode = false;
                                  return { ...d };
                                }),
                              data: {
                                isEditMode: true,
                                index: index1,
                                post: post,
                              },
                              user_id: user?.id,
                            };
                          });
                        }}
                      >
                        <RemixIcon
                          name="ri-pencil-fill"
                          className="self-start mt-1 ml-2"
                          size={20}
                          color={colors.app.d1}
                        />
                      </Pressable>
                      <Pressable
                        onPress={() => {
                          setShowConfirmModal((d) => ({
                            ...d,
                            visible: true,
                            BodyComponent: () => (
                              <Font>
                                Do you want to delete{" "}
                                <Font className="text-app-f capitalize">
                                  {post.title}
                                </Font>{" "}
                                Post?
                              </Font>
                            ),
                            onConfirm: () => {
                              (async () => {
                                const token = await isSignedIn();
                                axiosInstance
                                  .delete(
                                    apiURIV2 +
                                      "/users/" +
                                      user.id +
                                      "/posts/" +
                                      post.id,
                                    {
                                      headers: {
                                        Authorization: "Bearer " + token,
                                      },
                                    }
                                  )
                                  .then((res) => {
                                    if (res.status === 204) {
                                      setPostsData((data) => {
                                        data.splice(index1, 1);
                                        return cloneDeep(data);
                                      });
                                    }
                                  })
                                  .catch((e) =>
                                    console.log("e.res", e?.response, e)
                                  );
                              })();
                              setShowConfirmModal((d1) => {
                                d1.visible = false;
                                d1.onConfirm = () => null;
                                d1.onCancel = () => null;
                                d1.cancelText = "Cancel";
                                d1.confirmText = "Confirm";
                                d1.bodyText = "";
                                return { ...d1 };
                              });
                            },
                            onCancel: () =>
                              setShowConfirmModal((d1) => {
                                d1.visible = false;
                                d1.onConfirm = () => null;
                                d1.onCancel = () => null;
                                d1.cancelText = "Cancel";
                                d1.confirmText = "Confirm";
                                d1.bodyText = "";
                                return { ...d1 };
                              }),
                          }));
                        }}
                      >
                        <RemixIcon
                          name="ri-delete-bin-5-fill"
                          className="self-start mt-1 ml-1"
                          size={20}
                          color={colors.app.e1}
                        />
                      </Pressable>
                    </View>
                  </View>
                  {post?.description ? (
                    <View className="pt-4 pb-1">
                      <Font
                        className="text-app-d text-sm"
                        numberOfLines={showMoreDesc ? 0 : 2}
                      >
                        {post.description}
                      </Font>
                      {post.description?.length > 1350 && (
                        <Pressable
                          onPress={() => setShowMoreDesc(!showMoreDesc)}
                        >
                          <Font className="text-app-e">
                            {showMoreDesc ? "See less" : "See more"}
                          </Font>
                        </Pressable>
                      )}
                    </View>
                  ) : (
                    <></>
                  )}
                  {post?.cover_image_url ? (
                    <View className="rounded-lg h-72 w-full overflow-hidden pt-1">
                      <Image
                        source={{
                          uri: mediaURI + post.cover_image_url,
                        }}
                        className="flex-1"
                        resizeMode="contain"
                      ></Image>
                    </View>
                  ) : (
                    <></>
                  )}
                  <View className="py-2">
                    <View className="flex-row justify-items-stretch items-center border-y border-app-c">
                      <View className="flex-1 flex-row py-2 text-sm text-app-d">
                        <SVGLoader
                          src={
                            Platform.OS === "web"
                              ? require("../../../assets/like.svg")
                              : require("../../../assets/like.svg").default
                          }
                          width={24}
                          height={24}
                        />
                        <Font className="text-sm text-app-d self-center leading-4">
                          {post.likes}
                        </Font>
                      </View>
                      <Font className="p-2 text-sm text-app-d">
                        {post.comments} Comments
                      </Font>
                      <Font className="p-2 text-sm text-app-d">
                        {post.shares} Shares
                      </Font>
                    </View>
                  </View>
                  <View>
                    <View className="flex-row justify-between">
                      <Pressable
                        className="flex-row items-center"
                        onPress={() =>
                          (async () => {
                            const token = await isSignedIn();
                            axiosInstance
                              .post(
                                apiURIV2 +
                                  "/users/" +
                                  user.id +
                                  "/posts/" +
                                  post.id +
                                  (post.is_liked ? "/unlike" : "/like"),
                                {},
                                {
                                  headers: {
                                    Authorization: "Bearer " + token,
                                  },
                                }
                              )
                              .then((res) => {
                                if (res.status === 201) {
                                  setPostsData((data) => {
                                    const currPostData = data[index1];
                                    if (currPostData.is_liked) {
                                      if (currPostData?.likes) {
                                        currPostData.likes--;
                                      } else {
                                        currPostData.likes = 0;
                                      }
                                      currPostData.is_liked = false;
                                    } else {
                                      if (currPostData?.likes) {
                                        currPostData.likes++;
                                      } else {
                                        currPostData.likes = 1;
                                      }
                                      currPostData.is_liked = true;
                                    }
                                    data[index1] = currPostData;
                                    return [...data];
                                  });
                                }
                              })
                              .catch((e) =>
                                console.log("e.res", e?.response, e)
                              );
                          })()
                        }
                      >
                        <RemixIcon
                          name={
                            post.is_liked
                              ? "ri-thumb-up-fill"
                              : "ri-thumb-up-line"
                          }
                          className="self-center mr-2"
                          size={22}
                          color={post.is_liked ? colors.app.f : colors.app.d1}
                        />
                        <Font className="text-app-d1 text-sm leading-2">
                          {post.is_liked ? "Unlike" : "Like"}
                        </Font>
                      </Pressable>
                      <Pressable
                        className="flex-row items-center"
                        onPress={() =>
                          navigation.navigate("vendor-feed-comments", {
                            slug: post.id,
                          })
                        }
                      >
                        <RemixIcon
                          name="ri-message-2-line"
                          className="self-center mr-2"
                          size={22}
                          color={colors.app.d1}
                        />
                        <Font className="text-app-d1 text-sm leading-2">
                          Comment
                        </Font>
                      </Pressable>
                      <Pressable
                        className="flex-row items-center"
                        onPress={() => {
                          sharePostApi(
                            {
                              title: post?.title,
                              message: `${post?.title}\n\n${post?.description}\n\nhttps://smbexcustomer.wisdomtooth.tech/user-feed/timeline?id=${post.id}`,
                              // url: (
                              //   await fileURLtoBase64NBlob(
                              //     `${DEV_API}/media/` + post.cover_image_url
                              //   )
                              // ).base64,
                              url: `https://smbexcustomer.wisdomtooth.tech/user-feed/timeline?id=${post.id}`,
                            },
                            post.id,
                            index1
                          );
                        }}
                      >
                        <RemixIcon
                          name="ri-share-forward-line"
                          className="self-center mr-2"
                          size={22}
                          color={colors.app.d1}
                        />
                        <Font className="text-app-d1 text-sm leading-2">
                          Share
                        </Font>
                      </Pressable>
                    </View>
                  </View>
                </View>
              </Card>
            );
          }}
        />
      </View>
      {/* </View> */}
      <View
        className="absolute right-0 bottom-0  mb-4 mr-4"
        style={{ zIndex: 999999999 }}
      >
        <View className="relative">
          <Button
            className="h-18"
            label="Create Post"
            type="primary"
            shape="circ"
            onPress={() =>
              setShowCreatePostModal((d) => {
                return {
                  visible: true,
                  onRequestClose: () =>
                    setShowCreatePostModal((d) => {
                      d.visible = false;
                      d.data.post = null;
                      d.data.index = null;
                      d.data.isEditMode = false;
                      return { ...d };
                    }),
                  data: {
                    isEditMode: false,
                    index: null,
                    post: null,
                  },
                  user_id: user?.id,
                };
              })
            }
            iconName="ri-add-fill"
          />
        </View>
      </View>
      {showConfirmModal.visible && <ConfirmModal {...showConfirmModal} />}
      {showCreatePostModal.visible && (
        <NewPostModal
          {...showCreatePostModal}
          setShowResponseModal={setShowResponseModal}
          setPostsData={setPostsData}
        />
      )}
    </View>
  );
};

export default VendorFeed;
