import React from "react";
import {
  Image,
  View,
  Pressable,
  Platform,
  ImageBackground,
  StyleSheet,
} from "react-native";
import { Font, A } from "../../components/font";
import { Button, Input } from "../../components/input";
import { ScrollView } from "react-native";
import useInputHandler from "../../utils/useInputHandler";
import { signUp } from "../../actions/auth-actions";
import { email_format, password_format } from "../../constants/regex";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ResponseModal from "../../components/general/modals/ResponseModal";
import GoogleSigninFunction from "./googleSignin";
import { AuthContext } from "../../contexts/auth-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PhoneInput from "react-native-phone-input";

export default function Signup({ navigation }) {
  const { user, setUser } = React.useContext(AuthContext);
  const [userInfo, setUserInfo] = React.useState();
  const [isSigningUp, setIsSigningUp] = React.useState("");
  const [showAPIResponse, setShowAPIResponse] = React.useState({
    visible: false,
    onRequestClose: () => null,
    bodyText: "",
  });
  const [signUpData, setSignUpData] = React.useState({
    phoneNumber: "",
  });
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneValidationError, setPhoneValidationError] = React.useState("");
  const [isValid, setIsValid] = React.useState({
    phoneNumber: false,
  });
  const {
    currentValue: emailValue,
    // inputValue: emailInputValue,
    onBlurHandler: onEmailBlurHandler,
    onValueChangeHandler: onEmailChangeHandler,
    hasError: emailHasError,
  } = useInputHandler({
    validateFunction: (value) => {
      if (value?.match(email_format)) {
        return "";
      }
      return "Invalid Email";
    },
  });

  const {
    currentValue: passwordValue,
    // inputValue: passwordInputValue,
    onBlurHandler: onPasswordBlurHandler,
    onValueChangeHandler: onPasswordChangeHandler,
    hasError: passwordHasError,
  } = useInputHandler({
    validateFunction: (value) => {
      if (value?.match(password_format)) {
        if (confPassValue) onConfPassChangeHandler(confPassValue);
        return "";
      }
      return "Password should have at least - Upper Case & LowerCase Letter, Symbol, Number and Minimum 8 Characters";
    },
  });

  const validatePhoneNumber = () => {
    const phoneRegex = /^[2-9]\d{9}$/; // Match 10 digits starting from 2 to 9
    const cleanedPhoneNumber = signUpData.phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    if (
      cleanedPhoneNumber.length < 9 ||
      (cleanedPhoneNumber.length > 10 && !phoneRegex.test(cleanedPhoneNumber))
    ) {
      setPhoneValidationError("Please enter a valid 10-digit phone number");
      setIsValid({ ...isValid, phoneNumber: false });
    } else {
      setPhoneValidationError(""); // Clear the error message
      setIsValid({ ...isValid, phoneNumber: true });
    }
  };

  const {
    currentValue: confPassValue,
    // inputValue: passwordInputValue,
    onBlurHandler: onConfPassBlurHandler,
    onValueChangeHandler: onConfPassChangeHandler,
    hasError: confPassHasError,
  } = useInputHandler({
    validateFunction: (value) => {
      if (value === passwordValue) {
        return "";
      }
      return "Password does not match";
    },
  });

  // const onConfirmPasswordInput = (value) => {
  //   if(value!==passwordValue)
  // }

  const signUpHandler = async () => {
    try {
      if (
        !(emailValue && passwordValue && !(emailHasError && passwordHasError))
      ) {
        return;
      }
      setIsSigningUp(true);
      const res = await signUp({
        email: emailValue,
        phoneNumber: phoneNumber,
        password: passwordValue,
      });
      // console.log("res?.data: ", res?.data);
      if (res?.status === 201) {
        await AsyncStorage.setItem("h", res.data.hash);
        await AsyncStorage.setItem("userNumber", signUpData.phoneNumber);
        setShowAPIResponse({
          visible: true,
          bodyText: `${res?.status}: ${res.data?.message}`,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });

            navigation.replace("confirm-email-sent");
          },
          dismissText: "Okay",
        });
        // setUser(res.data.data);
      } else if (res?.status) {
        console.log("check error 1");
        setShowAPIResponse({
          visible: true,
          onRequestClose: () => {
            setShowAPIResponse({
              visible: false,
              onRequestClose: () => null,
              bodyText: "",
            });
          },
          bodyText: `${res?.status}: ${res.data?.body[0]?.message}`,
          dismissText: "Okay",
        });
      }
    } catch (error) {
      console.log("check error");

      setShowAPIResponse({
        visible: true,
        onRequestClose: () => {
          setShowAPIResponse({
            visible: false,
            onRequestClose: () => null,
            bodyText: "",
          });
        },
        bodyText: error?.response?.message,
      });
    } finally {
      setIsSigningUp(false);
    }
  };

  // const googleSignUp = () => {
  //   try {
  //     setIsGoogleSigningUp(true);
  //   } catch (error) {
  //     console.log(error, error?.code);
  //   } finally {
  //     setIsGoogleSigningUp(false);
  //   }
  // };

  return (
    <View className="w-full h-full bg-gray-50 flex-row">
      <View className="h-full w-full lg:w-1/2 items-center justify-center">
        <ScrollView
          className="w-full"
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          keyboardShouldPersistTaps="always"
        >
          <View
            className={
              "w-80 items-center py-16 " +
              (Platform.OS === "web"
                ? "py-8 px-10 md:px-0 w-full md:w-80"
                : "basis-0")
            }
          >
            <Pressable
              // onPress={() => navigation.replace("app")}
              className="mb-8 items-center"
            >
              <Image
                source={require("../../../assets/smbex-lo.png")}
                className="w-36 h-12 my-2"
              />
              <Font className="text-app-e">Vendor</Font>
            </Pressable>
            <View className="w-full my-2">
              <Font className="text-xl text-app-d" weight={500}>
                Sign up
              </Font>
            </View>
            {/* <View className="w-full my-2">
              <Button
              label="Sign up with google"
              leftIcon={{
                name: "google-fill",
                color: colors.app.d1,
              }}
              waitState={isGoogleSigningUp}
              showLoaderWithText
              onPress={googleSignUp}
              ></Button>
            </View> */}
            {Platform.OS == "web" ? (
              <GoogleOAuthProvider clientId="1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com">
                <GoogleSigninFunction
                  setUser={setUser}
                  setUserInfo={setUserInfo}
                  navigation={navigation}
                />
              </GoogleOAuthProvider>
            ) : (
              <GoogleSigninFunction
                setUser={setUser}
                setUserInfo={setUserInfo}
                navigation={navigation}
              />
            )}
            <View className="w-full h-4 items-center justify-center">
              <View className="w-full h-[1px] bg-gray-200 my-2"></View>
              <View className="absolute bg-gray-50 py-1 px-2">
                <Font>or</Font>
              </View>
            </View>
            <View className="w-full pb-2 flex-1">
              <Input
                type="email"
                label="Email"
                placeholder="user@example.com"
                value={emailValue}
                setValue={onEmailChangeHandler}
                onBlur={onEmailBlurHandler}
                note={emailHasError}
                invalid={!!emailHasError}
              />
            </View>
            <View className="w-full pb-2 flex-1 ">
              <View className="w-full h-8 items-center flex-row">
                <Font className="flex-1  text-app-d1" weight={600}>
                  Phone Number
                </Font>
              </View>
              <View className="rounded border border-gray-200 overflow-hidden h-10 bg-app-a px-2">
                <PhoneInput
                  className="  flex-row "
                  onPressFlag={() => null}
                  initialCountry="us"
                  initialValue="1"
                  value={phoneNumber}
                  onChangePhoneNumber={(text) => {
                    setPhoneNumber(text);
                    // Example validation logic
                    setIsValid(text.length >= 10);
                  }}
                  textStyle={styles.textInput}
                  textProps={{
                    placeholder: "Enter a phone number...",
                  }}
                  style={styles.phoneInput}
                />
              </View>
            </View>
            {phoneValidationError != "" && (
              <View className="w-full flex-row">
                <Font className="text-app-e">{phoneValidationError}</Font>
              </View>
            )}
            <View className="w-full pb-2 flex-1">
              <Input
                type="password"
                label="Password"
                placeholder="************"
                value={passwordValue}
                setValue={onPasswordChangeHandler}
                onBlur={onPasswordBlurHandler}
                note={passwordHasError}
                invalid={!!passwordHasError}
              />
            </View>
            <View className="w-full pb-2 flex-1">
              <Input
                type="password"
                // value={confirmPassword}
                // setValue={setConfirmPassword}
                label="Confirm Password"
                placeholder="************"
                value={confPassValue}
                setValue={onConfPassChangeHandler}
                onBlur={onConfPassBlurHandler}
                note={confPassHasError}
                invalid={!!confPassHasError}
                // validation={true}
              />
            </View>

            <View className="w-full pt-4 pb-2">
              <Button
                type="primary"
                label="Sign up"
                onPress={signUpHandler}
                waitState={isSigningUp}
                loaderWithText="Please Wait"
              ></Button>
            </View>
            <View className="w-full my-2 items-center justify-center flex-row flex-wrap">
              <Font className="text-xs text-app-d1 font-medium">
                Sign up for a SMBEx account means you agree to the
              </Font>
              <A
                href="/text"
                label=" Privacy Policy"
                className="font-medium text-app-e text-sm text-center text-xs "
              />
              <Font className="text-xs text-app-d1 font-medium"> and </Font>
              <A
                href="/text"
                label="Terms of Service"
                className="font-medium text-app-e text-sm text-center text-xs "
              />
            </View>
            <View className="w-full h-[1px] bg-gray-200 my-2"></View>
            <View className="w-full my-2">
              <Font className="text-center mb-2">Already have an account?</Font>
              <A
                href="signin"
                label="Sign in"
                className="font-semibold text-app-e text-sm text-center"
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <View className="h-full w-1/2 hidden lg:flex bg-app-d">
        <ImageBackground
          className="flex-1"
          source={require("../../../assets/side-background-image.jpg")}
          resizeMode="stretch"
          alt="side-background-home"
        />
      </View>
      <ResponseModal
        visible={showAPIResponse.visible}
        onRequestClose={showAPIResponse.onRequestClose}
        bodyText={showAPIResponse.bodyText}
        dismissText={showAPIResponse?.dismissText}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  inputContainer: {
    borderRadius: 5,
    borderWidth: 1,
    height: 40, // Equivalent to "h-10"
    backgroundColor: "#f9f9f9", // bg-app-a
    overflow: "hidden",
    paddingHorizontal: 10, // "px-3"
  },
  borderGray: {
    borderColor: "#e5e7eb", // Equivalent to "border-gray-200"
  },
  borderRed: {
    borderColor: "#f87171", // Equivalent to "border-red-500"
  },
  phoneInput: {
    flex: 1,
    justifyContent: "center",
  },
  textInput: {
    padding: 10,
    flex: 1,
    height: 40, // Align with parent container
    fontSize: 16,
    color: "#000", // Default text color
    ...(Platform.OS === "web" && {
      outlineColor: "transparent",
    }),
  },
});
