import { View, Text, Linking } from "react-native";
import React from "react";
import { Button } from "../../components/input";
import colors from "../../../colors";
import axiosInstance from "../../constants/axios-config";
import { apiURIV2 } from "../../constants";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { PleaseWaitModal } from "../../components/modal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { socket } from "../../services/socket-io";
import { getUserProfile } from "../../actions/auth-actions";
import { useNotifications } from "../../utils/useNotifications";

export const GoogleSignInToken = async (type, token, navigation) => {
  try {
    const res = await axiosInstance.post(
      apiURIV2 + "/auth/google/signin",
      type == "id_token"
        ? {
            role: "vendor",
            id_token: token,
          }
        : {
            role: "vendor",
            access_token: token,
          }
    );
    console.log(res, "GoogleSignInToken response log");
    // navigate after 200 status same as login
    if (res.status === 200) {
      if (!res?.data?.token) {
        throw new Error("jwt not received");
      }
      await AsyncStorage.setItem("ut", res.data.token || "");
      socket.connect();
      return await getUserProfile(res.data.token);
    } else {
      return res;
    }
  } catch (error) {
    if (error?.response) return error.response;
    // setIsLoading(false);
    console.log(
      error,
      error.response?.status,
      error.response?.data,
      "GoogleSignInToken error log"
    );
  }
};

const GoogleSigninFunction = ({ setUser, setUserInfo, navigation , signIn=false}) => {
  const { registerForPushNotificationsAsync } = useNotifications();
  const [isLoading, setIsLoading] = React.useState(false);
  const googleWebAuthenticationAsync = useGoogleLogin({
    // flow: "auth-code",
    redirect_uri: "https://wisdomtooth.tech/google",

    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse, "tokenResponse");
      const res = await GoogleSignInToken(
        "access_token",
        tokenResponse.access_token,
        navigation
      );
      console.log(res, "google signin res");

      if (res?.status == 200) {
        console.log(res?.data?.data, "res?.data?.data");
        setUser(res?.data?.data);
        setIsLoading(false);
        registerForPushNotificationsAsync();
        navigation.replace("app");
      } else {
        if (res?.status == 403) {
          if (res?.data?.data?.verification_entity == "phone") {
            navigation.navigate("verifyOtp", { hash: res?.data?.data?.hash });
          } else {
            await AsyncStorage.setItem("ut", res?.data?.token || "");
            socket.connect();
            registerForPushNotificationsAsync();
            navigation.navigate("phoneNumber");
          }
          return res;
        } else if (res?.status == 201) {
          console.log(res, "res from g sign in");
          await AsyncStorage.setItem("ut", res.data.token || "");
          registerForPushNotificationsAsync();
          socket.connect();
          navigation.replace("phoneNumber");
        } else if (res) {
          alert(res?.data?.message);
        }
      }

      setIsLoading(false);

      const userInfo = await axiosInstance
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      console.log(userInfo, "userInfo of gs");
      // onSuccess: async (codeResponse) => {
      //   console.log(codeResponse ,"success check" );
      // },
    },
    onError: (errorResponse) => console.log("onError:", errorResponse),
    onNonOAuthError: (errorResponse) =>
      console.log("onNonOAuthError:", errorResponse),
    // ux_mode: "popup",
    // include_granted_scopes: false
  });

  return (
    <GoogleOAuthProvider
      clientId="1049029176568-9m667iv9lb1fokt3h6vb0urnfg9m9r6q.apps.googleusercontent.com"

      // redirectUri="http://localhost:19006"
    >
      <View className="w-full my-2">
        {isLoading && <PleaseWaitModal />}
        <Button
          label={signIn ? "Sign in with Google" : "Sign up with Google"}
          leftIcon={{
            name: "google-fill",
            color: colors.app.d1,
          }}
          onPress={googleWebAuthenticationAsync}
        />
      </View>
    </GoogleOAuthProvider>
  );
};

export default GoogleSigninFunction;
